import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { searchToQuery } from '@zeals/shared-utils';
import { ApiQuery } from '@zeals/shared-types/build/dealer';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@material-ui/core';
import * as FullStory from '@fullstory/browser';
import jwtDecode from 'jwt-decode';
import config from '../../../config';
import { ENVS } from '../../../config/environments/types';
import useStyles from './styles';
import { useStores } from '../../../stores';
import Container from '../../../components/Container';
import { Routes } from '../../../navigations/Routes';
import DealerButton from '../../../components/DealerButton';
import CircleLoading from '../../../components/CircleLoading';

type TokenProps = {
  id: string;
};

const ServicesBlock: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const classes = useStyles();
  const { reservationStore } = useStores();
  const { t: translate } = useTranslation();
  const { vendorStore } = useStores();
  const { currentConfig, branch, merchant } = vendorStore;
  const { storeListUrl } = currentConfig;

  const { search } = useLocation();
  const { state, token } = searchToQuery<ApiQuery>(search);
  if (!token) throw new Error('token are required');

  if (config.environment === ENVS.PRODUCTION) {
    const decoded = jwtDecode<TokenProps>(token);
    FullStory.setUserVars({
      endUserId: decoded.id,
    });
  }

  const fetchData = () => {
    setLoading(true);
    reservationStore
      .getCustomFormData(token)
      .then((reservationResponse) =>
        reservationStore.setReservation({
          ...reservationStore.reservation,
          ...reservationResponse,
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!state) {
      reservationStore.resetReservation();
      reservationStore.setToken(token);
      fetchData();
    }
  }, [token]);

  const setServiceKey = (serviceKey: string) => {
    reservationStore.setServiceKey(serviceKey);
    history.push({ pathname: `/${merchant}/${branch}${Routes.Stores}` });
  };

  if (loading) {
    return (
      <Container title={translate('common.pageTitles.services')}>
        <CircleLoading isLoadingPage />;
      </Container>
    );
  }

  return (
    <Container title={translate('common.pageTitles.services')}>
      {currentConfig.services.map((serviceKey) => {
        return (
          <div key={serviceKey}>
            <DealerButton
              id="dealerButton"
              kind="select"
              onClick={() => setServiceKey(serviceKey)}
            >
              {serviceKey}
            </DealerButton>
          </div>
        );
      })}
      <div className={classes.otherRequestsContact}>
        <Typography>
          {translate('common.labels.otherRequestsContact')}
        </Typography>
        <DealerButton
          disabled={!storeListUrl}
          id="storeListUrl"
          fullWidth
          kind="info"
          style={{ marginTop: 16 }}
        >
          <Link href={storeListUrl} rel="noopener noreferrer" underline="none">
            {translate('common.buttons.contactStore')}
          </Link>
        </DealerButton>
      </div>
    </Container>
  );
};

export default ServicesBlock;

import React, { useEffect, useState } from 'react';

import firebase from 'firebase/app';
import { useStores, hydrateAll, RootStoreContext } from './stores';
import i18n from './i18n';

import config from './config';
import Router from './navigations/Router';

import './assets/style/app.css';

i18n();

firebase.initializeApp(config.firebaseConfig);

const App = (): React.ReactElement => {
  const [hydrated, setHydrated] = useState(false);
  const rootStore = useStores();

  useEffect(() => {
    hydrateAll(rootStore).then(() => setHydrated(true));
  }, [rootStore]);

  if (!hydrated) return null;

  return (
    <RootStoreContext.Provider value={rootStore}>
      <Router />
    </RootStoreContext.Provider>
  );
};

export default App;

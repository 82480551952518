import React from 'react';
import { Copyright } from '@zeals/shared-components';
import useStyles from './styles';
import ContentHeader from '../ContentHeader';
import Header from '../Header';
import { useStores } from '../../stores';

type ContainerProps = {
  children?: React.ReactNode;
  footerChildren?: React.ReactNode | React.ReactNodeArray;
  headerChildren?: React.ReactNode | React.ReactNodeArray;
  isForReservation?: boolean;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  previousPath?: () => void;
  selectedOptions?: Record<string | number, string>;
  showBackButton?: boolean;
  title?: string;
};

const ContentContainer: React.FC<ContainerProps> = ({
  children,
  footerChildren,
  headerChildren,
  isForReservation = true,
  justifyContent = 'flex-start',
  previousPath = () => {},
  selectedOptions,
  showBackButton = false,
  title = null,
}: ContainerProps) => {
  const { vendorStore } = useStores();
  const { currentConfig } = vendorStore || {};
  const { features } = currentConfig || {};
  const { showWhiteCopyright } = features || {};
  const classes = useStyles({ justifyContent });

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        {headerChildren ?? (
          <Header
            isForReservation={isForReservation}
            previousPath={previousPath}
            showBackButton={showBackButton}
          />
        )}
      </div>
      <div className={classes.content}>
        <div className={classes.contentChildren}>
          <ContentHeader
            isForReservation={isForReservation}
            selectedOptions={selectedOptions}
            title={title}
          />
          {children}
        </div>
      </div>
      <div className={classes.footer}>
        {footerChildren}
        <Copyright
          reserveFill={showWhiteCopyright}
          isPositionAbsolute={false}
        />
      </div>
    </div>
  );
};

export default ContentContainer;

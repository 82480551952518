import apisauce from 'apisauce';
import transformResponse from '@zeals/shared-utils';

import { api as apiTypes } from '@zeals/shared-types';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';
import { ReservedTimeRequest, SimplybookRequest } from './request';
import config from '../../config';

const api = apisauce.create({
  baseURL: config.serverUrl,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    console.log('error');
  }
});

export default {
  getReservedTime: (
    request: ReservedTimeRequest,
    token: string
  ): Promise<ApiResponseWrapper<any>> => {
    api.setBaseURL(config.serverUrl.replace(/\/v1/i, '/v2'));
    api.setHeaders({
      Authorization: `Bearer ${token}`,
    });

    const simplyBookRequest: SimplybookRequest = {
      branch: request.branch,
      merchant: request.merchant,
      method: 'getWorkDaysInfo',
      params: [request.from, request.to, request.unitId, request.eventId],
    };

    return transformResponse<any>(api.post, {
      payload: simplyBookRequest,
      url: `/req`,
    });
  },
};

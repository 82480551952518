import React, { ReactElement } from 'react';
import {
  Dialog as MuiModal,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  Divider,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useStores } from '../../../stores';

import useStyles from './styles';

type DialogProps = {
  body?: {
    className?: string;
    content?: ReactElement;
  };
  footer?: {
    className?: string;
    content: ReactElement;
  };
  onClose: (open: boolean) => void;
  open?: boolean;
  showLogo?: boolean;
};

const BaseModal: React.FC<DialogProps> = ({
  body = {
    className: '',
    content: null,
  },
  footer = {
    className: '',
    content: null,
  },
  onClose,
  open = false,
  showLogo = true,
}) => {
  const { vendorStore } = useStores();
  const { svgModalLogo, currentConfig } = vendorStore;
  const { modalLogo } = currentConfig || {};
  const { width, height } = modalLogo || {};
  const classes = useStyles({ width, height });

  return (
    <MuiModal open={open} classes={{ paper: classes.paper }}>
      <div className={classes.container}>
        <MuiDialogTitle disableTypography className={classes.headerContainer}>
          {showLogo && (
            <div
              className={classes.logoWrapper}
              dangerouslySetInnerHTML={{ __html: svgModalLogo }}
            />
          )}
          <IconButton aria-label="close" onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <div className={body.className || classes.contentContainer}>
          {body.content}
        </div>
        <Divider />
        <MuiDialogActions
          className={footer.className || classes.footerContainer}
        >
          {footer.content}
        </MuiDialogActions>
      </div>
    </MuiModal>
  );
};

export default BaseModal;

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { queryToSearch } from '@zeals/shared-utils';
import { ApiQuery } from '@zeals/shared-types/build/dealer';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../stores';
import Container from '../../../components/Container';

import { Routes } from '../../../navigations/Routes';
import AccordionButtons from './AccordionButtons';
import ListStoreButton from './ListStoreButton';
import { Store } from '../../../config/jsonConfiguration/backendConfiguration';

const StoresBlock: React.FC = () => {
  const history = useHistory();
  const { reservationStore, vendorStore } = useStores();
  const { serviceKey, token } = reservationStore;
  const { t: translate } = useTranslation();
  const { currentConfig, branch, merchant } = vendorStore;
  const { stores, features } = currentConfig;

  const setStoreKey = useCallback(
    (storeKey: string) => {
      reservationStore.setStoreKey(storeKey);
      history.push({
        pathname: `/${merchant}/${branch}${Routes.ReservationForm}`,
      });
    },
    [reservationStore]
  );

  const storesList = useMemo(() => {
    if (features?.showAreas) {
      return stores.reduce((obj, store) => {
        obj[store.area] = [...(obj[store.area] || []), store];
        return obj;
      }, {});
    }

    return stores;
  }, [stores, features]);

  return (
    <Container
      selectedOptions={{
        service: serviceKey,
      }}
      showBackButton
      previousPath={() => {
        reservationStore.resetReservation();
        history.push({
          pathname: `/${merchant}/${branch}${Routes.Services}`,
          search: queryToSearch<ApiQuery>({ token }),
        });
      }}
      title={translate('common.pageTitles.stores')}
    >
      {Array.isArray(storesList) ? (
        <ListStoreButton stores={storesList} setStoreKey={setStoreKey} />
      ) : (
        <AccordionButtons
          areaStores={storesList as Record<string, Store[]>}
          setStoreKey={setStoreKey}
        />
      )}
    </Container>
  );
};

export default StoresBlock;

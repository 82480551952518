import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import useStyles from '../styles';
import { InputFieldProps, Checkbox } from '../../../../../../components/Form';
import { useStores } from '../../../../../../stores';
import config from '../../../../../../config';

type TermsAndConditionsFieldBlockProps = {
  onClick: (url: string) => void;
} & InputFieldProps;

const TermsAndConditionsFieldBlock: React.FC<TermsAndConditionsFieldBlockProps> = ({
  nameField,
  onClick,
}) => {
  const { vendorStore } = useStores();
  const classes = useStyles();
  const { currentConfig } = vendorStore;
  const { features, pdf } = currentConfig || {};
  const { privacyPolicy, termsAndConditions } = pdf || {};
  const { t: translate } = useTranslation();
  const privacyPolicyFile = `${config.serverUrl}files/${privacyPolicy}/privacyPolicy.pdf`;
  const termsAndConditionsFile = `${config.serverUrl}files/${termsAndConditions}/termsAndConditions.pdf`;

  return (
    <div className={classes.checkBoxField}>
      <Checkbox
        label={
          <span>
            <Trans
              i18nKey={`common.form.termsAndConditions`}
              components={[
                <Link
                  href="#"
                  key="TermsAndConditions"
                  rel="noreferrer noopener"
                  underline="always"
                  onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    onClick(termsAndConditionsFile);
                  }}
                  target="none"
                />,
                <Link
                  href="#"
                  key="PrivacyPolicy"
                  rel="noreferrer noopener"
                  underline="always"
                  onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    onClick(privacyPolicyFile);
                  }}
                  target="none"
                />,
              ]}
            />
          </span>
        }
        name={nameField}
        props={{
          classes: {
            root: classes.checkbox,
            checked: classes.checked,
          },
        }}
        rules={{ required: true }}
      />
      <div className={classes.termsAndConditionsAdditionalInfo}>
        {features?.showPolicyAdditionalInfo &&
          translate(`common.form.termsAndConditionsAdditionalInfo`)}
      </div>
    </div>
  );
};

export default TermsAndConditionsFieldBlock;

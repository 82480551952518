import { makeStyles, Theme } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

type Props = {
  hasSelectedOption: boolean;
};

export default makeStyles<Theme, Props>((theme) => ({
  blankDiv: {
    height: (props: Props) =>
      spacingToPixel(props.hasSelectedOption ? 4 : 3, theme),
  },
  infoContentHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoTitleText: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '200%',
  },
  title: {
    marginBottom: spacingToPixel(3, theme),
    marginTop: spacingToPixel(3, theme),
  },
  titleText: {
    fontSize: '1em',
    fontWeight: 'bold',
  },
}));

import React from 'react';
import useStyles from './styles';
import { useStores } from '../../stores';

type TopBarProps = {
  className?: string;
};

const TopBar: React.FC<TopBarProps> = ({ className }) => {
  const { vendorStore } = useStores();
  const {
    currentConfig: { logo },
    svgLogo,
  } = vendorStore;
  const { source, width, height } = logo || {};
  const classes = useStyles({ width, height });

  if (!source) return null;

  return (
    <div
      className={[classes.logo, className].join(' ')}
      dangerouslySetInnerHTML={{ __html: svgLogo }}
    />
  );
};

export default TopBar;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

type InfoFieldBlockProps = {
  name: string;
  value: string;
};

const InfoFieldBlock: React.FC<InfoFieldBlockProps> = ({ name, value }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  // Special case to display in summary
  if (name === 'substituteVehicle' && !value) {
    return null;
  }

  if (!name) {
    return null;
  }

  return (
    <div className={classes.infoField}>
      <Typography variant="body2">
        {translate(`common.form.labels.${name}`)}
      </Typography>
      <Typography className={'fs-mask'}>{value}</Typography>
    </div>
  );
};

export default InfoFieldBlock;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FabButton } from '@zeals/shared-components';
import { Reservation } from '@zeals/shared-types/build/dealer';
import { useStores } from '../../../../stores';
import useStyles from './styles';
import Container from '../../../../components/Container';
import InfoFieldBlock from '../../../../components/InfoField';
import { DataRetirevalErrorModal } from '../../../../components/modals';
import { Routes } from '../../../../navigations/Routes';
import config from '../../../../config';
import { SendEmailRequestBody } from '../../../../services/api/request/sendEmailRequest';

const ReservationSummaryBlock: React.FC = () => {
  const history = useHistory();
  // This is the order to show all informations in summary page
  const reservationKeys: Array<keyof Reservation> = [
    'service',
    'store',
    'name',
    'phone',
    'vehicleType',
    'licensePlate',
    'substituteVehicle',
    'reservationDate1st',
    'reservationDate2nd',
    'reservationDate3rd',
    'otherRequests',
  ];

  const displayOrder: Array<string> = [...new Set(reservationKeys)];

  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { emailStore, reservationStore, vendorStore } = useStores();
  const { merchant, branch } = vendorStore;
  const [showError, setShowError] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { reservation, token } = reservationStore;

  const getSubstituteVehicleTranslation = (
    substituteVehicle: string
  ): string => {
    if (!substituteVehicle) return null;

    return translate(
      `common.form.options.substituteVehicle.${substituteVehicle}`
    );
  };

  const handleSubmit = () => {
    setIsFetching(true);
    const body: SendEmailRequestBody = Object.keys(reservation).reduce(
      (obj: SendEmailRequestBody, key: keyof SendEmailRequestBody) => {
        return {
          ...obj,
          branch,
          merchant,
          [key]: reservation[key],
          substituteVehicle: getSubstituteVehicleTranslation(
            reservation.substituteVehicle
          ),
          talkRoomURL: config.talkRoomURL,
        };
      },
      {} as SendEmailRequestBody
    );

    const { name, phone, vehicleType, licensePlate } = body;

    Promise.resolve()
      .then(async () => {
        const isUpdatingSuccessful = await reservationStore.updateCustomForm({
          token,
          body: {
            branch,
            merchant,
            name,
            phone,
            vehicleType,
            licensePlate,
          },
        });
        if (!isUpdatingSuccessful) {
          setShowError(true);
        }
      })
      .then(async () => {
        const isSuccess = await emailStore.sendEmail({
          token,
          body,
        });

        if (!isSuccess) {
          setShowError(true);
          return;
        }
        reservationStore.resetReservation();
        history.push({
          pathname: `/${merchant}/${branch}${Routes.ThanksPage}`,
        });
      })
      .finally(() => {
        reservationStore.resetToken();
        setIsFetching(false);
      });
  };

  const footerButtonsContainer = (
    <div className={classes.buttonsContainer}>
      <FabButton
        className="editButton"
        disabled={isFetching}
        id="edit"
        onClick={() =>
          history.push({
            pathname: `/${merchant}/${branch}${Routes.ReservationForm}`,
          })
        }
        variant="extended"
      >
        {translate('common.summary.buttons.edit')}
      </FabButton>
      <FabButton
        className="sendButton"
        id="submit"
        onClick={handleSubmit}
        loading={isFetching}
        variant="extended"
      >
        {translate('common.summary.buttons.send')}
      </FabButton>
    </div>
  );

  return (
    <>
      <Container footerChildren={footerButtonsContainer}>
        <div className={classes.titleAlert}>
          まだ予約は確定していませんので、 予約情報をご確認ください。
        </div>
        {displayOrder.map((key) => {
          if (key === 'substituteVehicle') {
            return (
              <InfoFieldBlock
                key={key}
                name={key}
                value={getSubstituteVehicleTranslation(
                  reservation.substituteVehicle
                )}
              />
            );
          }

          return (
            <InfoFieldBlock key={key} name={key} value={reservation[key]} />
          );
        })}
      </Container>
      <DataRetirevalErrorModal
        onClose={() => setShowError(false)}
        open={showError}
      />
    </>
  );
};

export default ReservationSummaryBlock;

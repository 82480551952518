// import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  otherRequestsContact: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacingToPixel(3, theme),
  },
}));

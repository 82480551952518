import React from 'react';
import { RulesProps } from '@zeals/shared-components';
import { DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import InputField from '../Form/InputField';

type ProfileFieldsProps = {
  errors: DeepMap<Record<string, unknown>, FieldError>;
};

type Field = { nameField: string; rules: RulesProps };

const fields = (translate: TFunction): Field[] => [
  { nameField: 'name', rules: { maxLength: 50, required: true } },
  {
    nameField: 'phone',
    rules: {
      maxLength: {
        message: translate('common.form.errors.phone.length'),
        value: 11,
      },
      minLength: {
        message: translate('common.form.errors.phone.length'),
        value: 10,
      },
      pattern: {
        message: translate('common.form.errors.phone.validation'),
        value: /^[0-9]*$/,
      },
      required: true,
    },
  },
  { nameField: 'vehicleType', rules: { required: true } },
  {
    nameField: 'licensePlate',
    rules: {
      required: true,
    },
  },
];

const ProfileFields: React.FC<ProfileFieldsProps> = ({ errors = {} }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      {fields(translate).map(({ nameField, rules }) => (
        <InputField
          key={nameField}
          nameField={nameField}
          errors={errors}
          required
          rules={rules}
        />
      ))}
    </>
  );
};

export default ProfileFields;

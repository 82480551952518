import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  backButton: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3),
    '&>a': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: theme.spacing(1),
      '&>svg': {
        height: '1rem',
      },
    },
  },
}));

import { createContext, useContext } from 'react';
import { create } from 'mobx-persist';
import BookingStore from './bookingStore';
import EmailStore from './emailStore';
import ReservationStore from './reservationStore';
import VendorStore from './vendorStore';

const namespace = '@ZEALS_DX_DEALER';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

export class RootStore {
  bookingStore = new BookingStore(this);

  emailStore = new EmailStore(this);

  reservationStore = new ReservationStore(this);

  vendorStore = new VendorStore(this);
}

const hydrateAll = async (rootStore: RootStore): Promise<void> => {
  try {
    await Promise.all([
      hydrate(`${namespace}:booking`, rootStore.bookingStore),
      hydrate(`${namespace}:reservation`, rootStore.reservationStore),
      hydrate(`${namespace}:vendor`, rootStore.vendorStore),
    ]);
  } catch (err) {
    console.log(rootStore);
    // clean localStorage
    localStorage.removeItem(`${namespace}:booking`);
    localStorage.removeItem(`${namespace}:reservation`);
    localStorage.removeItem(`${namespace}:vendor`);
  }
};

const rootStore = new RootStore();

const RootStoreContext = createContext<RootStore>(rootStore);

const useStores = (): RootStore => {
  return useContext(RootStoreContext);
};

export { rootStore, useStores, hydrateAll, RootStoreContext };

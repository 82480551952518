import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from '../BaseModal';
import useStyles from './styles';

type DataRetrievalErrorProps = {
  onClose: (open: boolean) => void;
  open?: boolean;
};

const DataRetrievalErrorModal: React.FC<DataRetrievalErrorProps> = ({
  onClose,
  open = false,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  const bodyContent = (
    <>
      <Typography variant="body1" className="title">
        {translate('common.modal.messages.dataRetrieval.title')}
      </Typography>
      <Typography variant="body1" className="message">
        {translate('common.modal.messages.dataRetrieval.message')}
      </Typography>
    </>
  );

  const footerContent = (
    <>
      <Button
        className={classes.closeButton}
        id="close"
        onClick={() => onClose(false)}
      >
        {translate('common.buttons.close')}
      </Button>
    </>
  );

  return (
    <BaseModal
      body={{ className: classes.contentContainer, content: bodyContent }}
      footer={{ content: footerContent }}
      onClose={onClose}
      open={open}
    />
  );
};

export default DataRetrievalErrorModal;

import { makeStyles, Theme } from '@material-ui/core';
import { spacingToPixel } from '@zeals/shared-utils';

type CSSProps = {
  width: string;
  height: string;
};

export default makeStyles<Theme, CSSProps>((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'minmax(64px, auto) 1fr 1px minmax(64px, auto)',
    maxHeight: '95vh',
    maxWidth: 'fit-content',
    width: '100%',
  },
  contentContainer: {
    color: '#80B29D',
    padding: spacingToPixel(4, theme),
  },
  footerContainer: {
    paddingBottom: spacingToPixel(4, theme),
    paddingLeft: spacingToPixel(4, theme),
    paddingRight: spacingToPixel(4, theme),
    paddingTop: spacingToPixel(3, theme),
  },
  headerContainer: {
    alignItems: 'center',
    background: theme.modal.headerBackground,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingLeft: spacingToPixel(4, theme),
    paddingRight: 0,
    paddingTop: 0,
    '&>button': {
      marginRight: '12px',
    },
  },
  paper: {
    alignItems: 'center',
    background: theme.palette.common.white,
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 'fit-content',
    minWidth: 'fit-content',
  },
  logoWrapper: ({ width, height }) => {
    return {
      '&>svg': {
        width: width || 'unset',
        height: height || 'unset',
      },
    };
  },
}));

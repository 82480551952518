import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, ErrorMessage } from '@zeals/shared-components';
import useStyles from './styles';

const UnknownErrorPage: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.dialogContainer}>
        <Dialog
          header={{
            className: classes.header,
          }}
          body={{
            children: (
              <ErrorMessage title={translate('common.errors.unknown.title')} />
            ),
          }}
          buttons={{
            children: null,
          }}
        />
      </div>
    </div>
  );
};

export default UnknownErrorPage;

import { blue, green, teal } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';
import { ThemeButtons } from './button';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    buttons: ThemeButtons;
    logo: {
      fillColor: string;
    };
    inputColor: {
      background: string;
      icon: string;
      placeholder: string;
    };
    loadingColor: {
      modal: string;
      page: string;
    };
    modal: {
      headerBackground: string;
      submitButton: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    button?: ThemeButtons;
    logo?: {
      fillColor: string;
    };
    inputColor?: {
      background: string;
      icon: string;
      placeholder: string;
    };
    loadingColor?: {
      modal: string;
      page: string;
    };
    modal?: {
      headerBackground: string;
      submitButton: string;
    };
  }
}

const buttons: ThemeButtons = {
  calendar: {
    hover: {
      background: '#00653B',
      color: '#fff',
    },
    normal: {
      background: '#F2F7F5',
      color: '#00653B',
    },
  },
  cancel: {
    normal: {
      background: '#fff',
      border: blue[900],
      color: blue[900],
    },
    hover: {
      background: '#00653B',
      color: '#fff',
    },
  },
  info: {
    hover: {
      background: teal[400],
      color: '#00653B',
    },
    normal: {
      background: '#00653B',
      border: '#fff',
      color: '#fff',
    },
  },
  select: {
    disabled: {
      background: green[100],
      color: '#00653B',
    },
    hover: {
      background: green[100],
      color: '#00653B',
    },
    normal: {
      background: '#fff',
      color: '#000',
    },
  },
  submit: {
    hover: {
      background: '#00A496',
      color: '#fff',
    },
    normal: {
      background: '#009688',
      color: '#fff',
    },
  },
};

export default createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
        height: 56,
        marginBottom: 8,
        fontSize: '1em',
        fontWeight: 'bold',
        textTransform: 'capitalize',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#000',
      },
      root: {
        color: '#fff',
        fontSize: '1em',
        fontWeight: 900,
      },
    },
    MuiLink: {
      root: {
        fontWeight: 'bold',
        textDecoration: 'none',
        color: '#fff',
        cursor: 'pointer',
      },
    },
    // @ts-ignore
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: 'unset',
      },
      week: {
        margin: '4px 0',
        '&>div>button': {
          fontSize: '0.875rem',
        },
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        maxHeight: 'unset',
        '& > span': {
          color: '#CCE0D8',
          lineHeight: '30px',
          width: '30px',
        },
      },
      switchHeader: {
        marginBottom: 0,
        marginLeft: 4,
        marginRight: 4,
        marginTop: 0,
        '& > div > p': {
          color: '#00653B',
          fontSize: '0.875rem',
          fontWeight: 'bold',
        },
        '& > button': {
          color: '#00653B',
        },
      },
    },
    MuiPickersDay: {
      current: {
        color: '#00653B',
        border: 'solid 1px',
      },
      day: {
        color: '#00653B',
        width: '30px',
        height: '30px',
      },
      daySelected: {
        backgroundColor: '#00653B',
        '&:hover': {
          backgroundColor: '#00653B',
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '1em',
        fontWeight: 'bold',
      },
      body2: {
        color: rgba('#fff', 0.5),
        fontSize: '0.875em',
      },
      root: {
        color: '#fff',
      },
    },
  },
  inputColor: {
    background: '#fff',
    icon: '#00653B',
    placeholder: '#000',
  },
  buttons,
  logo: {
    fillColor: '#fff',
  },
  modal: {
    headerBackground:
      'linear-gradient(90deg, #CED9D5 0%, rgba(183, 203, 195, 0) 100%), #FFFFFF',
    submitButton: teal[500],
  },
  loadingColor: {
    modal: '#00653B',
    page: '#fff',
  },
  palette: {
    background: {
      default: '#00653B',
    },
    primary: {
      main: '#00653B',
    },
    secondary: {
      main: '#fff',
    },
    success: {
      main: '#009688',
    },
    text: {
      primary: '#000',
      secondary: '#fff',
    },
    warning: {
      main: '#fff',
    },
  },
  spacing: [2, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256, 512, 1024, 2048],
  typography: {
    button: {
      fontWeight: 800,
      fontSize: 14,
    },
    fontFamily: 'Noto Sans JP',
    h5: {
      color: '#fff',
      fontWeight: 'bold',
      lineHeight: 2,
    },
    h6: {
      fontWeight: 'bold',
    },
    subtitle1: {
      color: '#80B29D',
    },
    subtitle2: {
      color: rgba('#000', 0.2),
      fontSize: '0.75em',
      fontWeight: 'bold',
    },
  },
});

import { makeStyles, Theme } from '@material-ui/core/styles';

type CSSProps = {
  width: string;
  height: string;
};

export default makeStyles<Theme, CSSProps>((theme) => ({
  logo: ({ width, height }) => {
    return {
      alignItems: 'center',
      display: 'flex',
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
      '&>svg': { width: width || 'unset', height: height || 'unset' },
    };
  },
}));

import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';
import { ThemeButtons } from './button';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    buttons: ThemeButtons;
    inputColor: {
      background: string;
      icon: string;
      placeholder: string;
    };
    loadingColor: {
      modal: string;
      page: string;
    };
    logo: {
      fillColor: string;
    };
    modal: {
      headerBackground: string;
      submitButton: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    button?: ThemeButtons;
    logo?: {
      fillColor: string;
    };
    inputColor?: {
      background: string;
      icon: string;
      placeholder: string;
    };
    loadingColor?: {
      page: string;
      modal: string;
    };
    modal?: {
      headerBackground: string;
      submitButton: string;
    };
  }
}

const createTheme = (
  themePrimary: string,
  themeMid: string,
  themeLight: string,
  themeBlack: string,
  themeWhite: string,
  themeSuccess: string,
  themeError: string
): Theme => {
  return createMuiTheme({
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
          fontSize: '1em',
          fontWeight: 'bold',
          height: 56,
          marginBottom: 8,
          textTransform: 'capitalize',
        },
      },
      MuiInputBase: {
        root: {
          background: themeError,
        },
      },
      MuiInputLabel: {
        root: {
          color: themeBlack,
          fontSize: '1em',
          fontWeight: 700,
        },
        outlined: {
          color: themeBlack,
        },
      },
      MuiLink: {
        root: {
          color: themeBlack,
          cursor: 'pointer',
          fontWeight: 'bold',
          textDecoration: 'none',
        },
      },
      // @ts-ignore
      MuiPickersCalendar: {
        week: {
          margin: '4px 0',
          '&>div>button': {
            fontSize: '0.875rem',
          },
        },
        transitionContainer: {
          marginTop: 'unset',
        },
      },
      MuiPickersCalendarHeader: {
        daysHeader: {
          maxHeight: 'unset',
          '& > span': {
            lineHeight: '30px',
            width: '30px',
            color: rgba(themePrimary, 0.5),
          },
        },
        switchHeader: {
          marginBottom: 0,
          marginLeft: 4,
          marginRight: 4,
          marginTop: 0,
          '& > div > p': {
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: themePrimary,
          },
          '& > button': {
            color: themePrimary,
          },
        },
      },
      MuiPickersDay: {
        day: {
          color: themePrimary,
          height: '30px',
          width: '30px',
        },
        daySelected: {
          backgroundColor: themePrimary,
          '&:hover': {
            backgroundColor: themePrimary,
          },
        },
        current: {
          border: 'solid 1px',
          color: themePrimary,
        },
      },
      MuiTypography: {
        root: {
          color: themeBlack,
        },
        body1: {
          fontSize: '1em',
          fontWeight: 'bold',
        },
        body2: {
          color: rgba(themePrimary, 0.5),
          fontSize: '0.875em',
        },
      },
    },
    buttons: {
      cancel: {
        normal: {
          background: themeWhite,
          border: themePrimary,
          color: themePrimary,
        },
        hover: {
          background: themePrimary,
          color: themeWhite,
        },
      },
      calendar: {
        hover: {
          background: themePrimary,
          color: themeWhite,
        },
        normal: {
          background: themeLight,
          color: themePrimary,
        },
      },
      info: {
        hover: {
          background: themeLight,
          color: themeWhite,
        },
        normal: {
          background: themeWhite,
          color: themePrimary,
          border: themePrimary,
        },
      },
      select: {
        hover: {
          background: themeLight,
          color: themePrimary,
        },
        normal: {
          background: themePrimary,
          color: themeWhite,
        },
      },
      submit: {
        normal: {
          background: themeSuccess,
          color: themeWhite,
        },
        hover: {
          background: themeSuccess,
          color: themeWhite,
        },
      },
    },
    logo: {
      fillColor: themePrimary,
    },
    inputColor: {
      icon: themePrimary,
      background: themeLight,
      placeholder: themeMid,
    },
    loadingColor: {
      modal: themePrimary,
      page: themePrimary,
    },
    modal: {
      headerBackground: `linear-gradient(90deg, rgba(0, 91, 172, 0.05) 0%, rgba(0, 91, 172, 0) 100%), ${themeWhite}`,
      submitButton: themePrimary,
    },
    palette: {
      background: {
        default: themeWhite,
      },
      primary: {
        main: themePrimary,
      },
      secondary: {
        main: themeWhite,
      },
      success: {
        main: themeSuccess,
      },
      text: {
        primary: themeBlack,
        secondary: themeWhite,
      },
      warning: {
        main: themeBlack,
      },
    },
    spacing: [2, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256, 512, 1024, 2048],
    typography: {
      button: {
        fontWeight: 800,
        fontSize: 14,
      },
      fontFamily: 'Noto Sans JP',
      h5: {
        color: themePrimary,
        fontWeight: 'bold',
        lineHeight: 2,
      },
      h6: {
        fontWeight: 'bold',
      },
      subtitle1: {
        color: rgba(themePrimary, 0.5),
      },
      subtitle2: {
        color: rgba(themeBlack, 0.2),
        fontSize: '0.75em',
        fontWeight: 'bold',
      },
    },
  });
};

export default createTheme;

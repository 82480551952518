import { makeStyles } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.common.white,
    '& .MuiIconButton-label': {
      position: 'relative',
      zIndex: 0,
    },
    '&:not($checked) .MuiIconButton-label:after': {
      content: '""',
      left: spacingToPixel(1, theme),
      top: spacingToPixel(1, theme),
      height: spacingToPixel(3, theme),
      width: spacingToPixel(3, theme),
      position: 'absolute',
      backgroundColor: 'white',
      zIndex: -1,
    },
    '&$checked': {
      color: theme.palette.common.white,
    },
  },
  checkBoxField: {
    marginBottom: spacingToPixel(3, theme),
    marginTop: spacingToPixel(6, theme),
  },
  checked: {},
  divider: {
    background: theme.palette.common.white,
    marginTop: spacingToPixel(5, theme),
  },
  disabled: {},
  error: {
    color: theme.palette.warning.main,
  },
  inputField: {
    marginTop: spacingToPixel(3, theme),
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: 'underline',
  },
  linkText: {
    fontSize: '15px',
  },
  select: {
    background: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  selectField: {
    marginTop: spacingToPixel(3, theme),
  },
  selectIcon: {
    color: theme.palette.common.black,
  },
  selectOutlined: {
    borderRadius: spacingToPixel(2, theme),
  },
  selectRoot: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  textArea: {
    borderRadius: spacingToPixel(2, theme),
  },
  textAreaField: {
    background: theme.inputColor.background,
    color: theme.palette.common.black,
    '&>textarea::-ms-input-placeholder': {
      color: theme.inputColor.placeholder,
    },
    '&>textarea::placeholder': {
      color: theme.inputColor.placeholder,
    },
  },
  textFieldInput: {
    borderRadius: spacingToPixel(2, theme),
    height: spacingToPixel(7, theme),
    '&$disabled': {
      color: theme.palette.common.black,
    },
  },
  textFieldInputField: {
    background: theme.inputColor.background,
    color: theme.palette.common.black,
    '&>input::-ms-input-placeholder': {
      color: theme.inputColor.placeholder,
    },
    '&>input::placeholder': {
      color: theme.inputColor.placeholder,
    },
  },
  labelContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: spacingToPixel(2, theme),
    width: '100%',
    '& >.mandatoryLabel': {
      alignItems: 'center',
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      marginLeft: spacingToPixel(1, theme),
      maxHeight: '18px',
      maxWidth: '35px',
      width: '100%',
    },
  },
}));

import { action, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { RootStore } from './rootStore';
import { NewVendorConfiguration } from '../config/jsonConfiguration/backendConfiguration';
import adminApi from '../services/api/adminApi';
import { makeTimeSlotsPerDay } from './helpers';

class VendorStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable status: 'successful' | 'failed' | 'fetching' = 'fetching';

  @observable isFetching = false;

  @persist @observable merchant: string;

  @persist @observable branch: string;

  @persist('object') @observable timeSlotsPerDay: Record<number, string>;

  @persist('list') @observable timeSlotKeys: { from: number; to: number }[];

  @persist @observable svgLogo: string;

  @persist @observable svgModalLogo: string;

  @persist('object') @observable currentConfig: NewVendorConfiguration;

  @action async fetchLogo(id: string, token: string): Promise<string> {
    const logo = await adminApi.getSVGLogo(id, 'logo.svg', token);
    return logo.data;
  }

  @action async fetchLogoModal(id: string, token: string): Promise<string> {
    const modalLogo = await adminApi.getSVGLogo(id, 'modalLogo.svg', token);
    return modalLogo.data;
  }

  @action async getConfiguration(
    { merchant, branch }: Record<'merchant' | 'branch', string>,
    token: string
  ): Promise<void> {
    try {
      if (this.isFetching || this.status === 'successful') return;

      if (!(branch || merchant)) {
        throw new Error('Branch and Merchant are required');
      }

      this.isFetching = true;
      this.status = 'fetching';

      const res = await adminApi.getAdminConfiguration(
        { merchant, branch },
        token
      );

      if (res.status !== 200) {
        throw new Error('No response json schema');
      }

      const [svgModalLogo, svgLogo] = await Promise.all([
        this.fetchLogoModal(res?.data?.modalLogo?.source, token),
        this.fetchLogo(res?.data?.logo?.source, token),
      ]);

      const [timeSlotsPerDay, timeSlotKeys] = makeTimeSlotsPerDay(
        res.data.calendar
      );

      runInAction(() => {
        this.timeSlotsPerDay = timeSlotsPerDay;
        this.timeSlotKeys = timeSlotKeys;
        this.merchant = merchant;
        this.branch = branch;
        this.currentConfig = res.data;
        this.svgLogo = svgLogo;
        this.svgModalLogo = svgModalLogo;
        this.isFetching = false;
        this.status = 'successful';
      });
    } catch (e) {
      console.error(e);
      this.status = 'failed';
    }
  }
}

export default VendorStore;

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ErrorBoundry, ScrollTop } from '@zeals/shared-components';
import * as FullStory from '@fullstory/browser';
import config from '../config';
import { ENVS } from '../config/environments/types';

import UnknownErrorPage from '../pages/errors/UnknownError';
import NoDealerRoute from './NoDealerRoute';
import NavigationRouter from './NavigationRouter';

const Router: React.FC = () => {
  if (config.environment === ENVS.PRODUCTION) {
    FullStory.init({
      orgId: config.fullstory.orgId,
    });
  }

  return (
    <BrowserRouter basename="/dealer">
      <ScrollTop>
        <ErrorBoundry fallback={<UnknownErrorPage />}>
          <Switch>
            <Route path={'/:merchant/:branch'}>
              <NavigationRouter customerIdPath={'/:merchant/:branch'} />
            </Route>
            <Route component={NoDealerRoute} />
          </Switch>
        </ErrorBoundry>
      </ScrollTop>
    </BrowserRouter>
  );
};

export default observer(Router);

import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  closeButton: {
    height: theme.spacing(6),
    background: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: spacingToPixel(1, theme),
    color: green[900],
    fontWeight: 'bold',
    '&:hover': {
      background: green[900],
      color: theme.palette.common.white,
    },
  },
  contentContainer: {
    padding: spacingToPixel(4, theme),
    width: '375px',
    '& >.title': {
      color: red[600],
      fontSize: '1em',
      fontWeight: 700,
      paddingBottom: spacingToPixel(4, theme),
    },
    '& >.message': {
      color: theme.palette.common.black,
      fontSize: '1em',
      fontWeight: 400,
    },
  },
}));

import { action, observable, runInAction } from 'mobx';
import { RootStore } from './rootStore';
import emailApi from '../services/api/emailApi';
import { SendEmailRequest } from '../services/api/request';

class EmailStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @action sendEmail = async (request: SendEmailRequest): Promise<boolean> => {
    try {
      const response = await emailApi.sendEmail(request);
      if (response?.status >= 400) {
        console.error(response.data?.message);
        return false;
      }

      return true;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };
}

export default EmailStore;

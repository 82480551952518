import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import { Reservation } from '@zeals/shared-types/build/dealer';
import { useStores } from '../../../../stores';
import useStyles from './styles';
import Container from '../../../../components/Container';
import { Routes } from '../../../../navigations/Routes';

import {
  ReservationDateFieldBlock,
  TermsAndConditionsFieldBlock,
} from './fields';
import {
  FlowConfirmationModal,
  PolicyViewerModal,
} from '../../../../components/modals';
import { InputField, SelectField } from '../../../../components/Form';
import ProfileFields from '../../../../components/ProfileFields';
import DealerButton from '../../../../components/DealerButton';

type SubstituteVehicleKeys = '' | 'doNotRequest' | 'request';

const ReservationFormBlock: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const [pdfFile, setPdfFile] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const { reservationStore, vendorStore } = useStores();
  const { currentConfig, branch, merchant } = vendorStore;
  const { features } = currentConfig;
  const { reservation, serviceKey, storeKey } = reservationStore;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: reservation,
  });
  const { errors, formState, handleSubmit, watch } = methods;
  const { t: translate } = useTranslation();

  const onSubmit = (formData: Record<string, string>) => {
    const {
      licensePlate,
      name,
      otherRequests,
      phone,
      reservationDate1st,
      reservationDate2nd,
      reservationDate3rd,
      substituteVehicle,
      vehicleType,
    } = formData;

    const { serviceKey: serviceName, storeKey: storeName } =
      reservationStore || {};

    const reservationObj: Reservation = {
      licensePlate,
      name,
      otherRequests,
      phone,
      reservationDate1st,
      reservationDate2nd,
      reservationDate3rd,
      service: serviceName,
      store: storeName,
      substituteVehicle,
      vehicleType,
    };
    reservationStore.setReservation(reservationObj);
    history.push({
      pathname: `/${merchant}/${branch}${Routes.ReservationSummary}`,
    });
  };

  const showSubstitueVehicleOption = () => {
    if (!features?.showSubstitueVehicleOption) return false;

    const notSupportingSubstituteVehicle = [
      '本店',
      'S.Stage伊福町',
      'Hi Touch東岡山',
      'YAHHO倉敷',
    ];

    if (
      reservationStore.serviceKey !== '車検' ||
      notSupportingSubstituteVehicle.includes(reservationStore.storeKey)
    ) {
      return false;
    }

    return true;
  };

  const substituteVehicleOptions: Record<SubstituteVehicleKeys, string> = {
    '': translate('common.form.options.substituteVehicle.default'),
    request: translate('common.form.options.substituteVehicle.request'),
    doNotRequest: translate(
      'common.form.options.substituteVehicle.doNotRequest'
    ),
  };

  const reservationDates = Object.keys(reservation).filter((key) =>
    key.startsWith('reservationDate')
  );

  return (
    <>
      <Container
        showBackButton
        selectedOptions={{
          service: serviceKey,
          store: storeKey,
        }}
        previousPath={() => setShowConfirmation(true)}
        title={translate(`common.pageTitles.reservationForm`)}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProfileFields errors={errors} />
            <Divider className={classes.divider} variant="middle" />
            {showSubstitueVehicleOption() && (
              <SelectField
                nameField="substituteVehicle"
                options={substituteVehicleOptions}
              />
            )}
            {reservationDates.map((name) => (
              <ReservationDateFieldBlock
                key={name}
                nameField={name}
                bookedDateTimes={
                  reservationDates.map(
                    (reservationDate) =>
                      reservationDate !== name && watch(reservationDate)
                  ) as string[]
                }
                defaultValue={reservation[name]}
                errors={errors}
                required
              />
            ))}
            <Divider className={classes.divider} variant="middle" />
            <InputField
              nameField="otherRequests"
              isTextArea
              errors={errors}
              props={{ rows: 6 }}
            />
            <TermsAndConditionsFieldBlock
              nameField="termsAndConditions"
              onClick={(file: string) => {
                setPdfFile(file);
                setShowPolicy(true);
              }}
            />
            <div>
              <DealerButton
                disabled={!formState.isValid}
                id="nextPage"
                kind="submit"
                type="submit"
              >
                {translate('common.buttons.confirm')}
              </DealerButton>
            </div>
          </form>
        </FormProvider>
      </Container>
      <FlowConfirmationModal
        title={translate('common.modal.messages.backConfirmation.title')}
        message={translate('common.modal.messages.backConfirmation.message')}
        confirmBtnTitle={translate('common.buttons.leavePage')}
        onClose={setShowConfirmation}
        onConfirm={() => {
          reservationStore.clearReservation();
          history.push({ pathname: `/${merchant}/${branch}${Routes.Stores}` });
        }}
        open={showConfirmation}
      />
      <PolicyViewerModal
        file={pdfFile}
        onClose={() => setShowPolicy(false)}
        open={showPolicy}
      />
    </>
  );
};

export default ReservationFormBlock;

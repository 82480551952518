import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Alert, EditSVG, WarnSVG } from '@zeals/shared-components';
import { ApiQuery, Reservation } from '@zeals/shared-types/build/dealer';
import { queryToSearch, searchToQuery } from '@zeals/shared-utils';
import useStyles from './styles';
import InfoFieldBlock from '../../../../components/InfoField';
import Container from '../../../../components/Container';
import { useStores } from '../../../../stores';
import { DataRetirevalErrorModal } from '../../../../components/modals';
import { Routes } from '../../../../navigations/Routes';
import DealerButton from '../../../../components/DealerButton';
import CircleLoading from '../../../../components/CircleLoading';

const Profile: React.FC = () => {
  const reservationKeys: Array<keyof Reservation> = [
    'name',
    'phone',
    'vehicleType',
    'licensePlate',
  ];

  const displayOrder: Array<string> = [...new Set(reservationKeys)];

  const classes = useStyles();
  const { search } = useLocation();
  const history = useHistory();
  const [showError, setShowError] = useState<boolean>(false);
  const [isFetchingError, setIsFetchingError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { state, token } = searchToQuery<ApiQuery>(search);
  if (!token) throw new Error('token is required');

  const { t: translate } = useTranslation();
  const { reservationStore, vendorStore } = useStores();
  const { branch, merchant } = vendorStore;
  const { reservation } = reservationStore;

  const fetchData = () => {
    setLoading(true);
    reservationStore
      .getCustomFormData(token)
      .then((reservationResponse) =>
        reservationStore.setReservation({
          ...reservation,
          ...reservationResponse,
        })
      )
      .catch((e) => {
        console.error(e);
        setIsFetchingError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!state) {
      reservationStore.resetReservation();
      reservationStore.setToken(token);
      fetchData();
    }
  }, [token]);

  useEffect(() => {
    if (state !== 'updated') return () => {};
    setShowAlert(true);
    const timeout = setTimeout(() => {
      setShowAlert(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [state]);

  // TODO: refactor it later
  const renderBody = (): JSX.Element => {
    if (loading) {
      return <CircleLoading isLoadingPage />;
    }

    if (isFetchingError) {
      return (
        <>
          <Typography component="div" className={classes.fetchingErrorMsg}>
            <WarnSVG scale={0.3} className={classes.icon} />
            <Typography>データを取得できませんでした</Typography>
          </Typography>
          <div className={classes.buttonsContainer}>
            <DealerButton
              id="reload"
              kind="submit"
              onClick={() => history.go(0)}
            >
              {translate('common.buttons.reload')}
            </DealerButton>
            <DealerButton
              id="services"
              kind="info"
              onClick={() =>
                history.push({
                  pathname: `/${merchant}/${branch}/${Routes.Services}`,
                  search: queryToSearch<ApiQuery>({
                    token,
                    state: 'preloaded',
                  }),
                })
              }
              fullWidth
            >
              {translate('profile.buttons.reservation')}
            </DealerButton>
          </div>
        </>
      );
    }

    return (
      <>
        {displayOrder.map((key) => (
          <InfoFieldBlock key={key} name={key} value={reservation[key]} />
        ))}
        <div className={classes.buttonsContainer}>
          <DealerButton
            id="submit"
            kind="submit"
            onClick={() =>
              history.push({
                pathname: `/${merchant}/${branch}${Routes.UpdateProfile}`,
              })
            }
          >
            <EditSVG className={classes.icon} />
            {translate('profile.buttons.updateInfo')}
          </DealerButton>
          <DealerButton
            id="services"
            kind="info"
            onClick={() =>
              history.push({
                pathname: `/${merchant}/${branch}${Routes.Services}`,
                search: queryToSearch<ApiQuery>({
                  token,
                  state: 'preloaded',
                }),
              })
            }
            fullWidth
          >
            {translate('profile.buttons.reservation')}
          </DealerButton>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.alertContainer}>
        <Alert
          message={translate('common.alert.success')}
          onClick={() => setShowAlert(false)}
          severity="success"
          showAlert={showAlert}
        />
      </div>
      <Container
        isForReservation={false}
        justifyContent={loading || isFetchingError ? 'center' : 'flex-start'}
        title={translate('common.summary.labels.inforTitle')}
      >
        {renderBody()}
      </Container>
      <DataRetirevalErrorModal
        onClose={() => setShowError(false)}
        open={showError}
      />
    </>
  );
};

export default Profile;

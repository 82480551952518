import { makeStyles, Theme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { rgba } from '@zeals/shared-utils';

export default makeStyles<Theme>((theme) => ({
  cancel: {
    background: theme.buttons.cancel.normal.background,
    border: `1px solid ${blue[900]}`,
    borderRadius: theme.spacing(2),
    boxShadow: 'none',
    color: blue[900],
    fontWeight: 'bold',
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: theme.buttons.cancel.hover.background,
      },
      background: theme.buttons.cancel.hover.background,
      color: theme.buttons.cancel.hover.color,
    },
  },
  calendar: {
    height: 40,
    color: theme.buttons.calendar.normal.color,
    background: theme.buttons.calendar.normal.background,
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: theme.buttons.calendar.hover.background,
      },
      backgroundColor: theme.buttons.calendar.hover.background,
      color: theme.palette.text.secondary,
    },
    '&.selected': {
      backgroundColor: theme.buttons.calendar.hover.background,
      color: theme.buttons.calendar.hover.color,
    },
  },
  info: {
    border: `1px solid ${theme.buttons.info.normal.border}`,
    background: theme.buttons.info.normal.background,
    color: theme.buttons.info.normal.color,
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: theme.buttons.info.hover.background,
      },
      backgroundColor: theme.buttons.info.hover.background,
      color: theme.buttons.info.hover.color,
    },
    '&>span>a': {
      color: theme.buttons.info.normal.color,
    },
  },
  select: {
    background: theme.buttons.select.normal.background,
    color: theme.buttons.select.normal.color,
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: theme.buttons.select.hover.background,
      },
      backgroundColor: theme.buttons.select.hover.background,
      color: theme.buttons.select.hover.color,
    },
  },
  submit: {
    background: theme.buttons.submit.normal.background,
    color: theme.buttons.submit.normal.color,
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(6),
    '&:disabled': {
      background: rgba(theme.buttons.submit.normal.background, 0.5),
      color: theme.buttons.submit.normal.color,
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: theme.buttons.submit.hover.background,
      },
      backgroundColor: theme.buttons.submit.hover.background,
      color: theme.buttons.submit.hover.color,
    },
  },
}));

import React, { useEffect } from 'react';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import { searchToQuery } from '@zeals/shared-utils';
import { ApiQuery } from '@zeals/shared-types/build/dealer';
import { Routes } from './Routes';

import ServicesBlock from '../pages/NavigationFlow/Services';
import StoresBlock from '../pages/NavigationFlow/Stores';
import ProfileForm from '../pages/NavigationFlow/Profile/Form';
import ReservationFormBlock from '../pages/NavigationFlow/Reservation/Form';
import ProfileInfo from '../pages/NavigationFlow/Profile/Info';
import ReservationSummaryBlock from '../pages/NavigationFlow/Reservation/Summary';
import ThanksBlock from '../pages/NavigationFlow/Thanks';
import NoRoute from './NoRoute';
import PrivateRoute from './PrivateRoute';
import createTheme from '../assets/theme/themeMaker';

import { useStores } from '../stores';
import { okayamaTheme } from '../assets/theme';
import { URLParams } from './URLParams';
import NoDealerRoute from './NoDealerRoute';
import LoadingDealer from './LoadingDealer';

type NavigationRouterProps = {
  customerIdPath: string;
};

const NavigationRouter: React.FC<NavigationRouterProps> = ({
  customerIdPath,
}) => {
  const { vendorStore } = useStores();
  const urlParams = useParams<URLParams>();
  const { search } = useLocation();
  const { token } = searchToQuery<ApiQuery>(search);

  useEffect(() => {
    vendorStore.getConfiguration(urlParams, token);
  }, [urlParams, token]);

  if (vendorStore?.status === 'fetching') {
    return <LoadingDealer />;
  }

  if (vendorStore?.status === 'failed') {
    return <NoDealerRoute />;
  }

  const { branch } = vendorStore || {};
  const { currentConfig } = vendorStore || {};
  const { theme, headerTitle } = currentConfig || {};
  const { primary, mid, light, black, white, success, error } = theme || {};

  let currentTheme = createTheme(
    primary,
    mid,
    light,
    black,
    white,
    success,
    error
  );

  if (branch === 'toyopetOkayama') {
    currentTheme = okayamaTheme;
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <Helmet>
        <title>{headerTitle}</title>
      </Helmet>
      <CssBaseline />
      <Switch>
        <Route
          exact
          path={`${customerIdPath}${Routes.Services}`}
          component={ServicesBlock}
        />
        <Route
          path={`${customerIdPath}${Routes.ThanksPage}`}
          component={ThanksBlock}
        />
        <PrivateRoute
          path={`${customerIdPath}${Routes.Stores}`}
          component={StoresBlock}
        />
        <PrivateRoute
          path={`${customerIdPath}${Routes.ReservationForm}`}
          component={ReservationFormBlock}
        />
        <PrivateRoute
          path={`${customerIdPath}${Routes.ReservationSummary}`}
          component={ReservationSummaryBlock}
        />
        {/* MY CAR INFORMATION */}
        <Route
          path={`${customerIdPath}${Routes.Profile}`}
          component={ProfileInfo}
        />
        <PrivateRoute
          path={`${customerIdPath}${Routes.UpdateProfile}`}
          component={ProfileForm}
        />
        <Route component={NoRoute} />
      </Switch>
    </ThemeProvider>
  );
};

export default observer(NavigationRouter);

import { makeStyles, Theme } from '@material-ui/core/styles';
import { isIOS } from 'react-device-detect';
import { spacingToPixel } from '@zeals/shared-utils';

type ContainerProps = {
  justifyContent: 'flex-start' | 'center' | 'flex-end';
};

export default makeStyles<Theme, ContainerProps>((theme) => ({
  content: {
    gridRow: 2,
    marginLeft: spacingToPixel(4, theme),
    marginRight: spacingToPixel(4, theme),
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  contentChildren: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: (props: ContainerProps) => props.justifyContent,
  },
  footer: {
    gridRow: 3,
    marginTop: spacingToPixel(5, theme),
  },
  header: {
    gridRow: 1,
    marginLeft: spacingToPixel(4, theme),
    marginRight: spacingToPixel(4, theme),
  },
  rootContainer: {
    background: theme.palette.background.default,
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, auto) minmax(0, 1fr) minmax(0, auto)',
    height: isIOS ? '-webkit-fill-available' : '100vh',
    margin: 0,
  },
}));

import { makeStyles, Theme } from '@material-ui/core';

type CircleLoadingStyleProps = {
  isLoadingPage: boolean;
};

export default makeStyles<Theme, CircleLoadingStyleProps>((theme) => ({
  loading: ({ isLoadingPage }) => {
    const baseCss = {
      alignItems: 'center',
      color: theme.loadingColor.modal,
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    };
    if (isLoadingPage) {
      return {
        ...baseCss,
        height: '100%',
        '&>div': {
          color: theme.loadingColor.page,
        },
      };
    }
    return baseCss;
  },
}));

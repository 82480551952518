import { EnvironmentConfig, ENVS } from './types';

const productionConfig: EnvironmentConfig = {
  email: {
    from: 'sgze1dh0@kke.com',
    to: 'toru.matsuda@zeals.co.jp',
  },
  environment: ENVS.PRODUCTION,
  firebaseConfig: {
    apiKey: 'AIzaSyDXC8SIfng-JyxwyCnv6UeianoFxoQED7g',
    authDomain: 'zeals-dx-prd.firebaseapp.com',
    databaseURL: 'https://zeals-dx-prd.firebaseio.com',
    projectId: 'zeals-dx-prd',
    storageBucket: 'zeals-dx-prd.appspot.com',
    messagingSenderId: '129733590022',
    appId: '1:129733590022:web:f7926845586f8f28c35787',
    measurementId: 'G-YBV6G9D773',
  },
  fullstory: {
    orgId: '13K68M',
  },
  tagManagerContainerId: '',
  serverUrl:
    process.env.REACT_APP_SERVER_URL ||
    'https://api.omotenashi.ai/reservation/v1/',
  sentryDst: '',
  sentryEnabled: false,
  talkRoomURL: 'https://fanp.me/dashboard/ad/chatbots/3589/talk/end_users',
  timeSlot: 2,
};

export default productionConfig;

import React from 'react';

const EmailLogoSVG = (props: any) => {
  return (
    <svg
      fill="none"
      height={61}
      width={71}
      viewBox="0 0 71 61"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M34 50.333C34 37.433 44.433 27 57.333 27c3.6 0 6.967.833 10 2.267V7c0-3.667-3-6.667-6.666-6.667H7.333C3.667.333.667 3.333.667 7v40c0 3.667 3 6.667 6.666 6.667h26.934c-.167-1.1-.267-2.2-.267-3.334zM7.333 7L34 23.667 60.667 7v6.667L34 30.333 7.333 13.667V7zM51.8 60.333L40 48.533l4.7-4.7 7.067 7.067L65.9 36.767l4.767 4.7L51.8 60.333z" />
    </svg>
  );
};

export default EmailLogoSVG;

import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { Reservation } from '@zeals/shared-types/build/dealer';
import { RootStore } from './rootStore';
import customFormApi from '../services/api/customFormApi';
import { UpdateCustomFormRequest } from '../services/api/request';

class ReservationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @persist @observable token = null;

  @persist('object') @observable reservation: Reservation = {
    licensePlate: null,
    name: null,
    otherRequests: null,
    phone: null,
    reservationDate1st: null,
    reservationDate2nd: null,
    reservationDate3rd: null,
    service: null,
    store: null,
    substituteVehicle: null,
    vehicleType: null,
  };

  @persist @observable serviceKey = '';

  @persist @observable storeKey = '';

  resetValue(noResetKeys: Array<keyof Reservation>): void {
    this.reservation = Object.keys(this.reservation).reduce(
      (obj: Reservation, key: keyof Reservation) => {
        if (!noResetKeys.includes(key)) {
          obj[key] = '';
        }
        return obj;
      },
      this.reservation
    );
  }

  @action clearReservation(): void {
    const noResetKeys: Array<keyof Reservation> = [
      'name',
      'phone',
      'vehicleType',
      'licensePlate',
    ];

    this.resetValue(noResetKeys);
  }

  @action resetReservation(): void {
    this.resetValue([]);
  }

  @action setToken(token: string): void {
    this.token = token;
  }

  @action resetToken(): void {
    this.token = null;
  }

  @action setReservation(reservation: Reservation): void {
    this.reservation = reservation;
  }

  @action setServiceKey(serviceKey: string): void {
    this.serviceKey = serviceKey;
  }

  @action setStoreKey(storeKey: string): void {
    this.storeKey = storeKey;
  }

  @action getCustomFormData = async (token: string): Promise<Reservation> => {
    const response = await customFormApi.getCustomFormData({ token });

    if (response.error) {
      const { message } = response.error;
      throw new Error(message);
    }

    if (response.status !== 200) {
      throw new Error();
    }

    // @ts-ignore
    const { custom_form_answers: customFormAnswers } = response.data;

    if (!customFormAnswers?.length) {
      return {} as Reservation;
    }

    const uniqueCustomAnswerObj = customFormAnswers.reduce((obj, answer) => {
      return {
        ...obj,
        [answer.custom_form_question.id]: {
          [answer.custom_form_question.label]:
            answer.custom_form_answer.text_field,
        },
      };
    }, {});

    return Object.values(uniqueCustomAnswerObj).reduce(
      (obj: Reservation, el: Reservation) => {
        return { ...obj, ...el };
      },
      {}
    ) as Reservation;
  };

  @action updateCustomForm = async (
    updateCustomFormData: UpdateCustomFormRequest
  ): Promise<boolean> => {
    const response = await customFormApi.updateCustomForm(updateCustomFormData);

    if (response?.status >= 400) {
      console.error(response.data?.message);
      return false;
    }

    return true;
  };
}

export default ReservationStore;

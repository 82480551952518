import { ENVS, EnvironmentConfig } from './environments/types';

import develop from './environments/develop';
import staging from './environments/staging';
import production from './environments/production';

const envMap: Record<string, EnvironmentConfig> = {
  [ENVS.DEVELOP]: develop,
  [ENVS.STAGING]: staging,
  [ENVS.PRODUCTION]: production,
};

export default envMap[process.env.REACT_APP_ENV] || develop;

import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import useStyles from './styles';
import { ButtonKind } from '../../assets/theme/button';

type Props = {
  className?: string;
  children: React.ReactNode | React.ReactNodeArray;
  isLoading?: boolean;
  kind: ButtonKind;
} & ButtonProps;

const DealerButton: React.FC<Props> = ({
  className,
  children,
  isLoading = false,
  kind,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      className={[classes[kind], className].join(' ')}
      fullWidth
      {...props}
    >
      {isLoading ? <CircularProgress color="inherit" size={24} /> : children}
    </Button>
  );
};

export default DealerButton;

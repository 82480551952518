import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { PdfViewer } from '@zeals/shared-components';
import BaseModal from '../BaseModal';
import useStyles from './styles';

type PolicyViewerModalProps = {
  file?: string;
  onClose: (open: boolean) => void;
  open?: boolean;
};

const PolicyViewerModal: React.FC<PolicyViewerModalProps> = ({
  file,
  onClose,
  open = false,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  const bodyContent = <PdfViewer file={file} />;
  const footerContent = (
    <Button className="closeButton" id="close" onClick={() => onClose(false)}>
      {translate('common.buttons.close')}
    </Button>
  );

  return (
    <BaseModal
      body={{ className: classes.contentContainer, content: bodyContent }}
      footer={{
        className: classes.footerContainer,
        content: footerContent,
      }}
      onClose={onClose}
      open={open}
      showLogo={false}
    />
  );
};

export default PolicyViewerModal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { CssBaseline, Grid, Box, Typography } from '@material-ui/core';
import { InfoSVG } from '@zeals/shared-components';
import useStyles from './styles';

const NoDealerRoute: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  return (
    <>
      <CssBaseline />
      <Grid className={classes.root}>
        <Box className={classes.content}>
          <InfoSVG />
          <Typography variant="h6" className={classes.text}>
            {translate('router.noPage')}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default NoDealerRoute;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  center: {
    alignSelf: 'center',
  },
  informationSent: {
    fontSize: '1.125em',
    fontWeight: 700,
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  logo: {
    fill: theme.logo.fillColor,
    justifySelf: 'center',
  },
  logoContainer: {
    display: 'grid',
  },
  message: {
    marginTop: theme.spacing(4),
    fontSize: '1.125em',
    fontWeight: 500,
    textAlign: 'center',
    whiteSpace: 'break-spaces',
  },
}));

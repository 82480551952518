import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from '../BaseModal';
import useStyles from './styles';
import DealerButton from '../../DealerButton/DealerButton';

type FlowConfirmationModalProps = {
  onClose: (open: boolean) => void;
  onConfirm: () => void;
  open?: boolean;
  title?: string;
  message: string;
  confirmBtnTitle: string;
};

const FlowConfirmationModal: React.FC<FlowConfirmationModalProps> = ({
  onClose,
  onConfirm,
  open = false,
  title,
  message,
  confirmBtnTitle,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  const bodyContent = (
    <>
      {title && (
        <Typography variant="body1" className="title">
          {title}
        </Typography>
      )}
      <Typography variant="body1" className="message">
        {message}
      </Typography>
    </>
  );

  const footerContent = (
    <div className={classes.buttonsContainer}>
      <DealerButton id="close" kind="submit" onClick={() => onConfirm()}>
        {confirmBtnTitle}
      </DealerButton>
      <DealerButton id="close" kind="cancel" onClick={() => onClose(false)}>
        {translate('common.buttons.cancel')}
      </DealerButton>
    </div>
  );

  return (
    <BaseModal
      body={{ className: classes.contentContainer, content: bodyContent }}
      footer={{ content: footerContent }}
      onClose={onClose}
      open={open}
    />
  );
};

export default FlowConfirmationModal;

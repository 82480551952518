import React from 'react';
import DealerButton from '../../../../components/DealerButton';
import { useStores } from '../../../../stores';
import useStyles from './style';
import { Store } from '../../../../config/jsonConfiguration/backendConfiguration';

type Props = {
  stores: Store[];
  setStoreKey: (storeKey: string) => void;
};

const ListStoreButton: React.FC<Props> = ({ stores, setStoreKey }) => {
  const classes = useStyles();
  const { vendorStore } = useStores();
  const { currentConfig } = vendorStore;
  const { features } = currentConfig;
  return (
    <>
      {stores.map(({ storeName, address }) => {
        return (
          <div key={storeName}>
            <DealerButton
              kind="select"
              id={storeName}
              onClick={() => setStoreKey(storeName)}
            >
              {features?.showAreas ? (
                <div>
                  {storeName}
                  <div className={classes.address}>{address}</div>
                </div>
              ) : (
                storeName
              )}
            </DealerButton>
          </div>
        );
      })}
    </>
  );
};

export default ListStoreButton;

import { GetWorkDaysInfoSlotResponse } from '../bookingStore';

export const decimalTimeToString = (time: number): string => {
  const minutes = Number(time) * 60;
  const m = Math.floor(minutes % 60);
  const h = Math.floor((minutes - m) / 60);
  return `${h < 10 ? '0' : ''}${h.toString()}:${
    m < 10 ? '0' : ''
  }${m.toString()}`;
};

export function makeTimeSlotsPerDay({
  startTime,
  slotDurationDecimal,
}: {
  startTime: number;
  slotDurationDecimal: number[];
}): [Record<number, string>, { from: number; to: number }[]] {
  let result: Record<number, string> = {};
  const arrKeys: { from: number; to: number }[] = [];
  let baseTime = startTime;

  for (let index = 0; index < slotDurationDecimal.length; index += 1) {
    const from = baseTime;
    const to = baseTime + slotDurationDecimal[index];
    baseTime = to;
    result = {
      ...result,
      [to]: `${decimalTimeToString(from)}-${decimalTimeToString(to)}`,
    };
    arrKeys.push({
      from,
      to,
    });
  }
  return [result, arrKeys];
}

export function timeStringToFloat(time: string): number {
  const hoursMinutes = time.split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours + minutes / 60;
}

export function filterAvailableTimes(
  slotTimeKeys: { from: number; to: number }[],
  timeSlotsPerDay: Record<number, string>,
  availableRanges: GetWorkDaysInfoSlotResponse[]
) {
  const ok = [];

  for (let i = 0; i < availableRanges.length; i += 1) {
    for (let j = 0; j < slotTimeKeys.length; j += 1) {
      if (
        slotTimeKeys[j].to <= timeStringToFloat(availableRanges[i].to) &&
        slotTimeKeys[j].to > timeStringToFloat(availableRanges[i].from) &&
        slotTimeKeys[j].from >= timeStringToFloat(availableRanges[i].from)
      ) {
        ok.push(timeSlotsPerDay[slotTimeKeys[j].to]);
      }
    }
  }
  return ok;
}

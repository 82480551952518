import React from 'react';
import { Typography } from '@material-ui/core';
import InfoFieldBlock from '../InfoField';
import useStyles from './styles';

type ContentHeaderProps = {
  isForReservation: boolean;
  selectedOptions?: Record<string | number, string>;
  title: string;
};

const ContentHeader: React.FC<ContentHeaderProps> = ({
  isForReservation,
  selectedOptions,
  title,
}) => {
  const classes = useStyles({
    hasSelectedOption: !!selectedOptions,
  });
  if (isForReservation) {
    return (
      <>
        {selectedOptions &&
          Object.keys(selectedOptions).map((key) => (
            <InfoFieldBlock key={key} name={key} value={selectedOptions[key]} />
          ))}
        <div className={classes.blankDiv}></div>
        {title && (
          <div className={classes.title}>
            <Typography>{title}</Typography>
          </div>
        )}
      </>
    );
  }
  return (
    <div className={classes.infoContentHeader}>
      {title && (
        <div>
          <Typography variant="h5">{title}</Typography>
        </div>
      )}
    </div>
  );
};

export default ContentHeader;

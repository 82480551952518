import apisauce from 'apisauce';
import transformResponse from '@zeals/shared-utils';

import { api as apiTypes } from '@zeals/shared-types';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import { SendEmailRequest } from './request';
import { SendEmailResponse } from './response';
import config from '../../config';

const api = apisauce.create({
  // TODO: fetching calendar should be same version
  baseURL: config.serverUrl.replace(/\/v1/i, '/v3'),
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    console.log('error');
  }
});

export default {
  sendEmail: (
    request: SendEmailRequest
  ): Promise<ApiResponseWrapper<SendEmailResponse>> => {
    const { body, token } = request;
    api.setBaseURL(config.serverUrl.replace(/\/v1/i, '/v4'));
    api.setHeaders({
      Authorization: `Bearer ${token}`,
    });
    return transformResponse<SendEmailResponse>(api.post, {
      payload: body,
      url: `/mail`,
    });
  },
};

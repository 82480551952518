import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import EmailLogoSVG from './EmailLogo';
import Container from '../../../components/Container';

const ThanksBlock: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <Container justifyContent="center">
      <div className={classes.logoContainer}>
        <EmailLogoSVG className={classes.logo} />
      </div>
      <Typography className={classes.informationSent}>
        {translate('common.thanks.informationSent')}
      </Typography>
      <Typography className={classes.message}>
        {translate('common.thanks.message')}
      </Typography>
    </Container>
  );
};

export default ThanksBlock;

import apisauce from 'apisauce';
import transformResponse from '@zeals/shared-utils';

import { api as apiTypes } from '@zeals/shared-types';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import { CustomFormRequest, UpdateCustomFormRequest } from './request';
import { CustomFormResponse } from './response';
import config from '../../config';

const api = apisauce.create({
  baseURL: config.serverUrl,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    console.log('error');
  }
});

export default {
  getCustomFormData: (
    request: CustomFormRequest
  ): Promise<ApiResponseWrapper<CustomFormResponse>> => {
    const { token } = request;
    api.setHeaders({
      Authorization: `Bearer ${token}`,
    });
    api.setBaseURL(config.serverUrl.replace(/\/v1/i, '/v2'));
    return transformResponse<CustomFormResponse>(api.get, {
      url: `/enduser`,
    });
  },
  updateCustomForm: (
    request: UpdateCustomFormRequest
  ): Promise<ApiResponseWrapper<any>> => {
    const { body, token } = request;
    api.setHeaders({
      Authorization: `Bearer ${token}`,
    });
    api.setBaseURL(config.serverUrl.replace(/\/v1/i, '/v2'));
    return transformResponse<any>(api.post, {
      payload: body,
      url: '/enduser',
    });
  },
};

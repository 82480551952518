import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from '@zeals/shared-components';
import { ApiQuery, Reservation } from '@zeals/shared-types/build/dealer';
import { queryToSearch } from '@zeals/shared-utils';
import Container from '../../../../components/Container';
import { useStores } from '../../../../stores';
import useStyles from './styles';
import { Routes } from '../../../../navigations/Routes';

import ProfileFields from '../../../../components/ProfileFields';
import { FlowConfirmationModal } from '../../../../components/modals';
import DealerButton from '../../../../components/DealerButton';
import { UpdateCustomFormRequest } from '../../../../services/api/request';

const ProfileForm: React.FC = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const history = useHistory();
  const classes = useStyles();
  const { reservationStore, vendorStore } = useStores();
  const { merchant, branch } = vendorStore;
  const { reservation, token } = reservationStore;
  const [isFetching, setIsFetching] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: reservation,
  });
  const { errors, formState, handleSubmit } = methods;
  const { t: translate } = useTranslation();

  const onSubmit = (formData: Record<string, string>) => {
    setIsFetching(true);

    const { licensePlate, name, phone, vehicleType } = formData;
    const body = {
      branch,
      merchant,
      licensePlate,
      name,
      phone,
      vehicleType,
    };
    const updateData: UpdateCustomFormRequest = { body, token };

    reservationStore
      .updateCustomForm(updateData)
      .then((isSuccessful) => {
        if (!isSuccessful) {
          return setShowAlert(true);
        }

        const submitReservation: Reservation = {
          ...reservation,
          ...body,
        };

        reservationStore.setReservation(submitReservation);

        return history.push({
          pathname: `/${merchant}/${branch}${Routes.Profile}`,
          search: queryToSearch<ApiQuery>({
            state: 'updated',
            token,
          }),
        });
      })
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAlert(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [showAlert]);

  return (
    <>
      <div className={classes.alertContainer}>
        <Alert
          showAlert={showAlert}
          message={translate('common.alert.failed')}
          severity="error"
          onClick={() => setShowAlert(false)}
        />
      </div>
      <Container
        isForReservation={false}
        title={translate('common.summary.labels.inforTitle')}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProfileFields errors={errors} />
            <div className={classes.buttonWrapper}>
              <DealerButton
                disabled={!formState.isValid || isFetching}
                id="nextPage"
                isLoading={isFetching}
                kind="submit"
                type="submit"
              >
                {translate('common.buttons.save')}
              </DealerButton>
            </div>
          </form>
          <DealerButton
            id="backPage"
            kind="info"
            onClick={() => setShowConfirmation(true)}
          >
            {translate('common.buttons.back')}
          </DealerButton>
        </FormProvider>
      </Container>
      <FlowConfirmationModal
        confirmBtnTitle={translate('common.buttons.resetData')}
        message={translate('common.modal.messages.resetData.message')}
        onClose={setShowConfirmation}
        onConfirm={() => {
          history.push({
            pathname: `/${merchant}/${branch}${Routes.Profile}`,
            search: queryToSearch<ApiQuery>({
              state: 'preloaded',
              token,
            }),
          });
        }}
        open={showConfirmation}
      />
    </>
  );
};

export default ProfileForm;

import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingToPixel(2, theme),
    width: '100%',
  },
  contentContainer: {
    padding: spacingToPixel(4, theme),
    width: '375px',
    '& >.title': {
      color: red[600],
      fontWeight: 700,
      paddingBottom: spacingToPixel(4, theme),
    },
    '& >.message': {
      color: theme.palette.common.black,
      fontWeight: 400,
    },
  },
}));

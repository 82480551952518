import { action, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { RootStore } from './rootStore';
import bookingApi from '../services/api/bookingApi';
import { NewVendorConfiguration } from '../config/jsonConfiguration/backendConfiguration';
import { filterAvailableTimes } from './helpers';

export type AvailableSlotsForDateResponse = {
  duplicateCall: boolean;
  hours: Array<string>;
  isValid: boolean;
};

export type GetWorkDaysInfoSlotResponse = {
  from: string;
  to: string;
};

class BookingStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @persist('object') @observable availableTimeSlots: Array<string> = null;

  @action getAvailableSlotsForDate = async (
    branch: string,
    currentConfig: NewVendorConfiguration,
    date: string,
    merchant: string,
    serviceKey: string,
    storeName: string,
    timeSlotsPerDay: Record<number, string>,
    slotTimeKeys: { from: number; to: number }[],
    token: string
  ): Promise<AvailableSlotsForDateResponse> => {
    const failedResult: AvailableSlotsForDateResponse = {
      hours: [],
      duplicateCall: true,
      isValid: false,
    };

    if (this.fetching) {
      return failedResult;
    }

    this.fetching = true;

    try {
      const { stores } = currentConfig;

      const selectedStore = stores.find(
        (store) => store.storeName === storeName
      );
      let eventId = selectedStore?.regularCalendarId;
      if (serviceKey === '車検' && branch === 'toyopetOkayama') {
        eventId = selectedStore?.replacementVehicleCalendarId;
      }

      const response = await bookingApi.getReservedTime(
        {
          branch,
          eventId: eventId.toString(),
          from: date,
          merchant,
          to: date,
          unitId: '1',
        },
        token
      );

      const { data, error } = response;

      if (error) {
        return failedResult;
      }

      const availableSlotsRes: Array<GetWorkDaysInfoSlotResponse> =
        data[date] || [];

      const hours = filterAvailableTimes(
        slotTimeKeys,
        timeSlotsPerDay,
        availableSlotsRes
      );

      return {
        duplicateCall: false,
        hours,
        isValid: !hours.some((hour) => hour === 'inValid'),
      };
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };
}

export default BookingStore;

import React, { memo } from 'react';
import {
  Route,
  RouteProps,
  Redirect,
  useLocation,
  useParams,
} from 'react-router-dom';
import { URLParams } from './URLParams';
import { Routes } from './Routes';
import { useStores } from '../stores';

const PrivateRoute: React.FC<RouteProps> = ({ ...props }) => {
  const { reservationStore } = useStores();
  const { merchant, branch } = useParams<URLParams>();
  const { token } = reservationStore || {};
  const { search } = useLocation();

  if (!token) {
    return (
      <Route
        render={({ location }) => {
          return (
            <Redirect
              to={{
                pathname: `/${merchant}/${branch}${Routes.Services}`,
                search,
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  return <Route {...props} />;
};

export default memo(PrivateRoute);

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CalendarPicker } from '@zeals/shared-components';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import BaseModal from '../BaseModal';

import useStyles from './styles';
import { useStores } from '../../../stores';
import { DataRetirevalErrorModal } from '..';
import DealerButton from '../../DealerButton';
import CircleLoading from '../../CircleLoading';

type DateTimePickerModalProps = {
  bookedDateTimes?: Array<string>;
  dayOff?: string;
  defaultValue?: string;
  onClose: (open: boolean) => void;
  onSubmit?: (datetime: string) => void;
  open: boolean;
};

const DateTimePickerModal: React.FC<DateTimePickerModalProps> = ({
  bookedDateTimes = new Array<string>(),
  dayOff,
  defaultValue,
  onClose,
  onSubmit,
  open,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { bookingStore, reservationStore, vendorStore } = useStores();
  const {
    currentConfig,
    merchant,
    branch,
    timeSlotKeys,
    timeSlotsPerDay,
  } = vendorStore;
  const [hours, setHours] = useState(new Array<string>());
  const [loading, setLoading] = useState(false);

  const getSelectedDay = (dateTime: string): Date => {
    if (dateTime) {
      const regexDate = /^(19|20)\d\d[/](0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])/;
      const selectedDay = dateTime.match(regexDate);
      return new Date(selectedDay ? selectedDay[0] : '');
    }

    const today = new Date();
    const dayOfTheWeek = dayjs(today).format('ddd');
    if (dayOfTheWeek.toLowerCase() === dayOff) {
      today.setDate(today.getDate() + 1);
    }

    return today;
  };

  const getSelectedHour = (dateTime: string): string => {
    if (dateTime) {
      const regexHour = /([01][0-9]|2[0-3]):([0-5][0-9])[-]([01][0-9]|2[0-3]):([0-5][0-9])$/;
      const selectedHour = dateTime.match(regexHour);
      return selectedHour
        ? selectedHour[0]
        : translate('common.buttons.notSpecificed');
    }
    return '';
  };

  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    getSelectedDay(defaultValue)
  );

  const [selectedTime, setSelectedTime] = useState(
    getSelectedHour(defaultValue)
  );
  const [showError, setShowError] = useState(false);

  const filterHours = (reservedHours: Array<string>): Array<string> => {
    if (reservedHours.length === 0) {
      return [];
    }

    const notSpecified = translate('common.buttons.notSpecificed');
    reservedHours.unshift(notSpecified);

    if (bookedDateTimes.length === 0) {
      return reservedHours;
    }

    const bookedSlots = bookedDateTimes?.filter((bookedDatetime) => {
      const parsedDate = getSelectedDay(bookedDatetime);
      if (Number.isNaN(parsedDate)) {
        return false;
      }

      const date = new Date(parsedDate).toDateString();
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      const formattedSelectedDate = dayjs(selectedDate).format('YYYY-MM-DD');
      return formattedDate === formattedSelectedDate;
    });

    if (bookedSlots?.length === 0) {
      return reservedHours;
    }

    const bookedHours = bookedSlots?.map((bookedSlot) => {
      const parsedHours = getSelectedHour(bookedSlot);
      return parsedHours;
    });

    const availableHours = reservedHours.filter(
      (hour) => !bookedHours.includes(hour)
    );

    return [...new Set([notSpecified, ...availableHours])];
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    setLoading(true);
    const { storeKey, serviceKey, token } = reservationStore;
    const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
    bookingStore
      .getAvailableSlotsForDate(
        branch,
        currentConfig,
        formattedDate,
        merchant,
        serviceKey,
        storeKey,
        timeSlotsPerDay,
        timeSlotKeys,
        token
      )
      .then((response) => {
        if (response.duplicateCall) {
          return;
        }

        if (!response.isValid) {
          setShowError(true);
          return;
        }

        const filteredHours = filterHours(response.hours);
        setHours(filteredHours);
        setLoading(false);
      })
      .catch(() => setShowError(true));
  }, [selectedDate, open]);

  const onHandleSubmit = () => {
    const formattedDate = dayjs(selectedDate).format('YYYY/MM/DD');
    const datetime = `${formattedDate} ${selectedTime}`;
    onSubmit(datetime);
  };

  const availableTimeSlots = () => {
    return hours?.length ? (
      <div className={classes.timeContainer}>
        {hours.map((time) => (
          <DealerButton
            className={selectedTime === time ? 'selected' : ''}
            id={time}
            key={time}
            kind="calendar"
            name={time}
            onClick={() => setSelectedTime(time)}
          >
            {time}
          </DealerButton>
        ))}
      </div>
    ) : (
      <Typography className="noTime">
        {translate('common.form.modal.noTime')}
      </Typography>
    );
  };

  const content = (
    <>
      <div className="pickDay">
        <Typography className={classes.title} variant="subtitle1">
          {translate('common.form.modal.pickDay')}
        </Typography>
      </div>
      <CalendarPicker
        date={selectedDate}
        onChange={(value: MaterialUiPickersDate) => {
          setSelectedDate(value);
          setSelectedTime('');
        }}
        keyLocale="ja"
      />
      {loading ? <CircleLoading /> : availableTimeSlots()}
    </>
  );

  const footerContent = !showError && (
    <Button
      className="confirmButton"
      disabled={selectedTime === ''}
      id="confirm"
      onClick={onHandleSubmit}
    >
      {translate('common.form.modal.confirmButton')}
    </Button>
  );

  return showError ? (
    <DataRetirevalErrorModal
      onClose={() => {
        setShowError(false);
        onClose(false);
      }}
      open={showError}
    />
  ) : (
    <BaseModal
      body={{
        className: classes.contentContainer,
        content,
      }}
      footer={{
        className: classes.footerContainer,
        content: footerContent,
      }}
      onClose={onClose}
      open={open}
    />
  );
};

export default DateTimePickerModal;

import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import BackButton from '../BackButtonComponent';
import TopBar from '../TopBar';
import useStyles from './styles';
import { useStores } from '../../stores';

const Nagoya3ColorsLine: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#009944" />
      <rect fill="#E60013" />
      <rect fill="#005BAC" />
    </svg>
  );
};

type HeaderProps = {
  isForReservation: boolean;
  previousPath: () => void;
  showBackButton: boolean;
};

const Header: React.FC<HeaderProps> = ({
  isForReservation,
  previousPath,
  showBackButton,
}) => {
  const { vendorStore } = useStores();
  const { currentConfig, branch } = vendorStore;
  const { features } = currentConfig;
  const classes = useStyles();

  const isIconPositionMiddle = useMemo(() => {
    return features?.showHeaderColors;
  }, [features?.showHeaderColors]);

  if (isForReservation) {
    return (
      <Box className={isIconPositionMiddle ? classes.headerWrapper : ''}>
        {branch === 'toyopetNagoya' && (
          <Nagoya3ColorsLine className={classes.nagoyaThreeLinesLogo} />
        )}
        {showBackButton && <BackButton onClick={() => previousPath()} />}
        <TopBar className={classes.logo} />
      </Box>
    );
  }
  return (
    <div className={classes.infoContentHeader}>
      {branch === 'toyopetNagoya' && (
        <Nagoya3ColorsLine className={classes.nagoyaThreeLinesLogo} />
      )}
      <TopBar />
    </div>
  );
};

export default Header;

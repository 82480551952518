import { makeStyles } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  alertContainer: {
    left: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: spacingToPixel(4, theme),
    '&>button:first-child': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      height: 56,
      background: '#fff',
    },
    '&>button:last-child': {
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.white}`,
      height: 56,
      background: theme.palette.success.main,
    },
  },
  titleAlert: {
    background: theme.inputColor.background,
    border: `1px solid ${theme.inputColor.background}`,
    borderRadius: '8px',
    boxSizing: 'border-box',
    color: theme.inputColor.placeholder,
    fontSize: '16px',
    fontWeight: 700,
    height: '97px',
    lineHeight: '32px',
    padding: '16px',
    wordBreak: 'keep-all',
    width: '100%',
  },
}));

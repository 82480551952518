import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { rgba, spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  closeButton: {
    background: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: spacingToPixel(1, theme),
    color: green[900],
    fontWeight: 'bold',
    '&:hover': {
      background: green[900],
      color: theme.palette.common.white,
    },
  },
  contentContainer: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    padding: spacingToPixel(2, theme),
    width: '375px',
    '& >.title': {
      color: red[600],
      fontSize: '1em',
      fontWeight: 700,
      paddingBottom: spacingToPixel(4, theme),
    },
    '& >.message': {
      fontSize: '1em',
      fontWeight: 400,
    },
  },
  footerContainer: {
    paddingBottom: spacingToPixel(4, theme),
    paddingRight: spacingToPixel(4, theme),
    paddingTop: spacingToPixel(3, theme),
    '& >.closeButton': {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
      color: theme.modal.submitButton,
      height: '40px',
      width: 'unset',
      '&:active': {
        backgroundColor: theme.palette.common.white,
        color: theme.modal.submitButton,
      },
      '&:disabled': {
        color: rgba(theme.modal.submitButton, 0.2),
      },
    },
  },
}));

import { EnvironmentConfig, ENVS } from './types';

const stagingConfig: EnvironmentConfig = {
  email: {
    from: 'sgze1dh0@kke.com',
    to: 'or.perlman@zeals.co.jp',
  },
  environment: ENVS.STAGING,
  firebaseConfig: {
    apiKey: 'AIzaSyAO2PS9NtvgJH80IXHi46JwEgn_I28c7A8',
    authDomain: 'zeals-dx-stg.firebaseapp.com',
    databaseURL: 'https://zeals-dx-stg.firebaseio.com',
    projectId: 'zeals-dx-stg',
    storageBucket: 'zeals-dx-stg.appspot.com',
    messagingSenderId: '910369079356',
    appId: '1:910369079356:web:a59d030a8bc15872b1c339',
    measurementId: 'G-85H512NFNC',
  },
  tagManagerContainerId: '',
  serverUrl:
    process.env.REACT_APP_SERVER_URL ||
    'https://api.stg.omotenashi.ai/reservation/v1/',
  sentryDst: '',
  sentryEnabled: false,
  talkRoomURL: 'https://fanp.me/dashboard/ad/chatbots/3463/talk/end_users',
  timeSlot: 2,
};

export default stagingConfig;

import { makeStyles, Theme } from '@material-ui/core';
import { isIOS } from 'react-device-detect';

export default makeStyles<Theme>({
  root: {
    height: isIOS ? '-webkit-fill-available' : '100vh',
    position: 'relative',
    display: 'grid',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '24.44%',
      right: 0,
      top: 0,
      bottom: '30.47%',
      clipPath: 'polygon(0 0, 100% 100%, 100% 0)',
      background:
        'linear-gradient(215.55deg, rgba(0, 91, 165, 0.1) 29.13%, rgba(0, 91, 165, 0.3) 50.82%, rgba(0, 91, 165, 0.5) 73.44%)',
      opacity: 0.15,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: '39.75%',
      bottom: 0,
      background:
        'linear-gradient(228.65deg, rgba(0, 91, 165, 0.05) -11.37%, rgba(0, 91, 165, 0.15) 50.34%, rgba(0, 91, 165, 0.25) 112.04%)',
      opacity: 0.2,
      clipPath: 'polygon(0 100%, 100% 100%, 100% 0)',
    },
  },
  content: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    padding: '0 24px',
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '29px',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '-0.004em',
    color: '#919BBD',
  },
});

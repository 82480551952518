import React from 'react';
import { isMobile } from 'react-device-detect';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FormNativeSelectBlock from './NativeSelect';
import FormSelectBlock from './Select';
import LabelBlock from '../Label';
import useStyles from '../styles';
import { SelectFieldProps } from '../types';

const SelectField: React.FC<SelectFieldProps> = ({ nameField, options }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  const selectProps: SelectFieldProps = {
    nameField,
    props: {
      className: classes.select,
      classes: {
        icon: classes.selectIcon,
        outlined: classes.selectOutlined,
        root: classes.selectRoot,
      },
    },
    rules: {
      required: true,
    },
    options,
  };

  return (
    <div className={classes.selectField}>
      <LabelBlock
        htmlFor={nameField}
        name={translate(`common.form.labels.${nameField}`)}
      />
      <FormControl fullWidth variant="outlined">
        {isMobile ? (
          <FormNativeSelectBlock {...selectProps} />
        ) : (
          <FormSelectBlock {...selectProps} />
        )}
      </FormControl>
    </div>
  );
};

export default SelectField;

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, Select } from '@material-ui/core';
import { SelectFieldProps } from '../../types';

const FormSelect: React.FC<SelectFieldProps> = ({
  label,
  nameField,
  props,
  rules,
  options,
}) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        as={
          <Select {...props}>
            {Object.entries(options).map(([k, v]) => {
              return (
                <MenuItem key={k} value={k} disabled={!k}>
                  {v}
                </MenuItem>
              );
            })}
          </Select>
        }
        control={control}
        defaultValue=""
        label={label}
        name={nameField}
        rules={rules}
      />
    </>
  );
};

export default FormSelect;

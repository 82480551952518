import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormTextField, RulesProps } from '@zeals/shared-components';
import { TextFieldProps } from '@material-ui/core';
import useStyles from '../styles';
import LabelBlock from '../Label';
import { InputFieldProps } from '../types';
import { useStores } from '../../../stores';

const InputField: React.FC<InputFieldProps> = ({
  nameField,
  required = false,
  errors = {},
  rules = {},
  props = {},
  isTextArea = false,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { vendorStore } = useStores();
  const { currentConfig } = vendorStore;
  const { features } = currentConfig;

  const baseProps: TextFieldProps = {
    fullWidth: true,
    InputLabelProps: { shrink: false },
    InputProps: {
      classes: {
        input: 'fs-mask',
        root: isTextArea ? classes.textArea : classes.textFieldInput,
      },
      className: isTextArea
        ? classes.textAreaField
        : classes.textFieldInputField,
    },
    placeholder: translate(`common.form.placeholders.${nameField}`),
    variant: 'outlined',
    multiline: isTextArea,
  };

  const baseRules: RulesProps = {
    required,
  };

  let labelName = translate(`common.form.labels.${nameField}`);

  if (features?.showLicensePlateLast4Digits && nameField === 'licensePlate') {
    labelName = translate(`common.form.labels.licensePlate4Digits`);

    baseProps.placeholder = translate(
      `common.form.placeholders.licensePlate4Digits`
    );

    baseRules.maxLength = {
      message: translate('common.form.errors.licensePlate.length'),
      value: 4,
    };
    baseRules.minLength = {
      message: translate('common.form.errors.licensePlate.length'),
      value: 4,
    };
    baseRules.pattern = {
      message: translate('common.form.errors.licensePlate.validation'),
      value: /^[0-9]*$/,
    };
  }

  return (
    <div className={classes.inputField}>
      <LabelBlock htmlFor={nameField} name={labelName} required={required} />
      <FormTextField
        error={errors[nameField]}
        errorClassName={classes.error}
        name={nameField}
        props={{ ...baseProps, ...props }}
        rules={{ ...baseRules, ...rules }}
      />
    </div>
  );
};

export default InputField;

import React from 'react';
import { WarnSVG } from '@zeals/shared-components';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { queryToSearch, searchToQuery } from '@zeals/shared-utils';
import ApiQuery from '@zeals/shared-types/build/dealer/apiQuery';
import { URLParams } from '../URLParams';
import Container from '../../components/Container';
import useStyles from './styles';
import { Routes } from '../Routes';
import DealerButton from '../../components/DealerButton';

const NoRoute: React.FC = () => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const { merchant, branch } = useParams<URLParams>();
  const history = useHistory();
  const { search } = useLocation();
  const { token } = searchToQuery<ApiQuery>(search);

  return (
    <Container>
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <WarnSVG className={classes.logo} />
        </div>
        <Typography variant="h6" className={classes.message}>
          {translate('common.router.notFound')}
        </Typography>
        <DealerButton
          id="goBack"
          kind="info"
          onClick={() =>
            history.push({
              pathname: `/${merchant}/${branch}${Routes.Services}`,
              search: queryToSearch<ApiQuery>({ token }),
            })
          }
        >
          {translate('common.buttons.back')}
        </DealerButton>
      </div>
    </Container>
  );
};

export default NoRoute;

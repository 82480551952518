import { Link, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import useStyles from './styles';

type BackButtonProps = {
  onClick(): void;
};

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  return (
    <div className={classes.backButton}>
      <Link onClick={onClick}>
        <ArrowBack />
        <Typography>{translate('common.buttons.back')}</Typography>
      </Link>
    </div>
  );
};

export default BackButton;

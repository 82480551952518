import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles<Theme>((theme) => ({
  divider: {
    background: theme.palette.primary.main,
  },
  root: {
    boxShadow: 'unset',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'unset',
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  group: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: 0,
    '&>div:last-child': {
      color: theme.palette.common.black,
    },
  },
  groupExtended: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
  },
}));

import { makeStyles, Theme } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles<Theme>((theme) => ({
  root: {
    margin: 'auto 0',
  },
  logo: {
    fill: theme.logo.fillColor,
    justifySelf: 'center',
    marginBottom: spacingToPixel(8, theme),
  },
  logoContainer: {
    display: 'grid',
  },
  message: {
    marginBottom: spacingToPixel(6, theme),
    fontWeight: 900,
    textAlign: 'center',
  },
}));

import apisauce from 'apisauce';
import transformResponse, { queryToSearch } from '@zeals/shared-utils';

import { api as apiTypes } from '@zeals/shared-types';
import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import config from '../../config';
import { URLParams } from '../../navigations/URLParams';
import { NewVendorConfiguration } from '../../config/jsonConfiguration/backendConfiguration';

const api = apisauce.create({
  baseURL: config.serverUrl,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    console.log('error');
  }
});

export default {
  getAdminConfiguration: (
    options: URLParams,
    token: string
  ): Promise<ApiResponseWrapper<NewVendorConfiguration>> => {
    const baseUrl = '/admin/public-config';
    const query = queryToSearch(options);

    api.setHeaders({
      Authorization: `Bearer ${token}`,
    });

    return transformResponse<NewVendorConfiguration>(api.get, {
      url: `${baseUrl}${query}`,
    });
  },
  getSVGLogo(
    id: string,
    fileName: string,
    token: string
  ): Promise<ApiResponseWrapper<string>> {
    const baseUrl = `/files/${id}/${fileName}`;
    api.setHeaders({
      Authorization: `Bearer ${token}`,
    });

    return transformResponse<string>(api.get, { url: baseUrl });
  },
};

import { makeStyles } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  alertContainer: {
    left: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  buttonsContainer: {
    marginTop: spacingToPixel(6, theme),
  },
  icon: {
    marginRight: spacingToPixel(2, theme),
  },
  fetchingErrorMsg: {
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme>((theme) => ({
  infoContentHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerWrapper: {
    alignItems: 'self-end',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '&>div': {
      margin: 'auto 0',
    },
  },
  logo: {
    gridColumn: 2,
  },
  nagoyaThreeLinesLogo: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 6,
    '&>rect': {
      width: 'calc(100%/3)',
      height: 6,
      '&:nth-child(2)': {
        x: 'calc(100%/3)',
      },
      '&:last-child': {
        x: 'calc(200%/3)',
      },
    },
  },
}));

export enum ENVS {
  DEVELOP = 'develop',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export type EnvironmentConfig = {
  email: {
    from: string;
    to: string;
  };
  environment: ENVS;
  firebaseConfig: {
    appId: string;
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    messagingSenderId: string;
    measurementId: string;
    projectId: string;
    storageBucket: string;
  };
  fullstory?: {
    orgId: string;
  };
  serverUrl: string;
  sentryDst: string;
  sentryEnabled: boolean;
  talkRoomURL: string;
  tagManagerContainerId: string;
  timeSlot: number;
};

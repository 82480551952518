import { CircularProgress } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

type CircleLoadingProps = {
  isLoadingPage?: boolean;
};

const CircleLoading: React.FC<CircleLoadingProps> = ({
  isLoadingPage = false,
}) => {
  const classes = useStyles({ isLoadingPage });
  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
};

export default CircleLoading;

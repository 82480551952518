import { InputLabel } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';

type LabelBlockProps = {
  htmlFor?: string;
  name: string;
  required?: boolean;
};

const LabelBlock: React.FC<LabelBlockProps> = ({
  htmlFor,
  name,
  required = true,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <div className={classes.labelContainer}>
      <InputLabel className="label" htmlFor={htmlFor}>
        {name}
      </InputLabel>
      {required && (
        <span className="mandatoryLabel">{translate('common.required')}</span>
      )}
    </div>
  );
};

export default LabelBlock;

import { makeStyles } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  buttonWrapper: {
    marginTop: spacingToPixel(5, theme),
  },
  divider: {
    background: theme.palette.common.white,
    marginTop: spacingToPixel(5, theme),
  },
  container: {
    position: 'relative',
  },
  alertContainer: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    zIndex: 1,
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { rgba, spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  contentContainer: {
    '& > .pickDay': {
      margin: '16px 24px 0px',
    },
    '& > .pickTime': {
      margin: '0px 24px 16px',
    },
    '& > .noTime': {
      color: theme.palette.common.black,
      fontWeight: 'normal',
      padding: '0px 24px 24px',
    },
  },
  footerContainer: {
    paddingBottom: spacingToPixel(4, theme),
    paddingRight: spacingToPixel(4, theme),
    paddingTop: spacingToPixel(3, theme),
    '& >.confirmButton': {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
      color: theme.modal.submitButton,
      height: '16px',
      width: 'unset',
      '&:active': {
        backgroundColor: theme.palette.common.white,
        color: theme.modal.submitButton,
      },
      '&:disabled': {
        color: rgba(theme.modal.submitButton, 0.2),
      },
    },
  },
  timeContainer: {
    display: 'grid',
    gap: spacingToPixel(2, theme),
    gridAutoRows: spacingToPixel(6, theme),
    gridTemplateColumns: 'repeat(2, 1fr)',
    height: '140px',
    margin: '0 24px 24px',
    overflowY: 'scroll',
  },
  title: {
    fontSize: '1em',
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { spacingToPixel } from '@zeals/shared-utils';

export default makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.common.white,
    '& .MuiIconButton-label': {
      position: 'relative',
      zIndex: 0,
      '&>svg': {
        fill: theme.logo.fillColor,
      },
    },
    '&:not($checked) .MuiIconButton-label:after': {
      content: '""',
      left: spacingToPixel(1, theme),
      top: spacingToPixel(1, theme),
      height: spacingToPixel(3, theme),
      width: spacingToPixel(3, theme),
      position: 'absolute',
      backgroundColor: 'white',
      zIndex: -1,
    },
    '&$checked': {
      color: theme.palette.common.white,
    },
  },
  icon: {
    fill: theme.inputColor.icon,
  },
  checkBoxField: {
    marginBottom: spacingToPixel(3, theme),
    marginTop: spacingToPixel(6, theme),
  },
  checked: {},
  divider: {
    background: theme.palette.common.white,
    marginTop: spacingToPixel(5, theme),
  },
  disabled: {},
  error: {
    color: theme.palette.common.white,
  },
  inputField: {
    marginTop: spacingToPixel(3, theme),
  },
  select: {
    background: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  selectField: {
    marginTop: spacingToPixel(3, theme),
  },
  selectIcon: {
    color: theme.palette.common.black,
  },
  selectOutlined: {
    borderRadius: spacingToPixel(2, theme),
  },
  selectRoot: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  termsAndConditionsAdditionalInfo: {
    color: theme.palette.common.black,
    fontSize: '0.875em',
  },
  textArea: {
    borderRadius: spacingToPixel(2, theme),
  },
  textAreaField: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  textFieldInput: {
    borderRadius: spacingToPixel(2, theme),
    height: spacingToPixel(7, theme),
    '&$disabled': {
      background: theme.inputColor.background,
      color: theme.palette.common.black,
    },
  },
  textFieldInputField: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  titleText: {
    color: theme.palette.common.white,
    fontSize: '1em',
    fontWeight: 'bold',
  },
}));

import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';
import { CalendarSVG } from '@zeals/shared-components';
import { TextFieldProps } from '@material-ui/core';
import useStyles from '../styles';
import { DateTimePickerModal } from '../../../../../../components/modals';
import { InputField, InputFieldProps } from '../../../../../../components/Form';

type DateFieldProps = {
  bookedDateTimes?: Array<string>;
  defaultValue: string;
} & InputFieldProps;

const ReservationDateFieldBlock: React.FC<DateFieldProps> = ({
  bookedDateTimes,
  defaultValue,
  errors,
  nameField,
  required = false,
}) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t: translate } = useTranslation();
  const { setValue } = useFormContext();

  const props: TextFieldProps = {
    onClick: () => setShowModal(true),
    disabled: true,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <CalendarSVG className={classes.icon} />
        </InputAdornment>
      ),
      classes: {
        disabled: classes.disabled,
        root: classes.textFieldInput,
      },
      className: classes.textFieldInputField,
    },
    placeholder: translate(`common.form.placeholders.${nameField}`),
  };

  return (
    <>
      <InputField
        nameField={nameField}
        required={required}
        props={props}
        errors={errors}
      />
      <DateTimePickerModal
        bookedDateTimes={bookedDateTimes}
        defaultValue={defaultValue}
        onSubmit={(time) => {
          setValue(nameField, time, { shouldValidate: true });
          setShowModal(false);
        }}
        onClose={setShowModal}
        open={showModal}
      />
    </>
  );
};

export default ReservationDateFieldBlock;

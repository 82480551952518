import { EnvironmentConfig, ENVS } from './types';

const developConfig: EnvironmentConfig = {
  email: {
    from: 'sgze1dh0@kke.com',
    to: 'or.perlman@zeals.co.jp',
  },
  environment: ENVS.DEVELOP,
  firebaseConfig: {
    apiKey: 'AIzaSyCa9BR8qH89sU0XiXcEjGI-8OdrTWvOCh4',
    authDomain: 'zeals-dx-dev.firebaseapp.com',
    databaseURL: 'https://zeals-dx-dev.firebaseio.com',
    projectId: 'zeals-dx-dev',
    storageBucket: 'zeals-dx-dev.appspot.com',
    messagingSenderId: '603262912759',
    appId: '1:603262912759:web:4b04d58dd1f12a2a15ebf2',
    measurementId: 'G-TL8W5FSGH1',
  },
  tagManagerContainerId: '',
  serverUrl: 'https://api.stg.omotenashi.ai/reservation/v1/',
  sentryDst: '',
  sentryEnabled: false,
  talkRoomURL: 'https://fanp.me/dashboard/ad/chatbots/3463/talk/end_users',
  timeSlot: 2,
};

export default developConfig;

import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './style';
import ListStoreButton from '../ListStoreButton';
import { Store } from '../../../../config/jsonConfiguration/backendConfiguration';

type AccordionButtonProps = {
  areaStores: Record<string, Store[]>;
  setStoreKey: (storeKey: string) => void;
};

const AccordionButtons: React.FC<AccordionButtonProps> = ({
  areaStores,
  setStoreKey,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const classes = useStyles();

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<Record<string, unknown>>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const renderChildren = () => {
    return Object.entries(areaStores).map(([area, stores]) => {
      return (
        <React.Fragment key={area}>
          <Accordion
            square
            classes={{
              root: classes.root,
            }}
            expanded={expanded === area}
            onChange={handleChange(area)}
          >
            <AccordionSummary
              className={classes.group}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{area}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.groupExtended}>
              <ListStoreButton stores={stores} setStoreKey={setStoreKey} />
            </AccordionDetails>
          </Accordion>
          <Divider className={classes.divider} />
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <Divider className={classes.divider} />
      {renderChildren()}
    </>
  );
};

export default AccordionButtons;
